.dtu-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 30px;
  border: 3px dashed var(--secondaryBackgroundOverlayMed);
  background: var(--secondaryBackgroundOverlayLight);

  &.dtu-empty-plain {
    border: 3px solid var(--secondaryBackgroundOverlayMed);
    background: unset;
  }

  .mat-icon {
    height: 60px;
    width: 60px;
    font-size: 60px;
    margin-bottom: 20px;
    color: var(--textLight);
  }

  .mat-button {
    margin-top: 15px;
  }
}

.dtu-empty-title {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.dtu-empty-subtitle {
  font-size: 0.875rem;
  color: var(--textLight);
}
