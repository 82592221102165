.dtu-toggle {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &.large {
    cursor: default;

    .dtu-toggle-switch {
      width: 56px;
      height: 30px;
      margin-bottom: 5px;
      border-radius: 15px;
      cursor: pointer;

      &::before {
        position: absolute;
        top: 3px;
        left: 3px;
        display: block;
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }
    }

    .dtu-toggle-details {
      margin-left: 10px;
    }

    .dtu-toggle-label {
      font-size: 1rem;
      font-weight: normal;
      color: var(--text);
    }

    .dtu-toggle-hint {
      color: var(--textLight);
      font-size: 0.75rem;
    }

    &.dtu-active .dtu-toggle-switch::before {
      left: unset;
    }
  }

  &.dtu-disabled {
    opacity: 0.6;
    cursor: default;

    .dtu-toggle-switch {
      cursor: default;
    }
  }
}

.dtu-toggle-label {
  margin-right: 10px;
  color: var(--textLight);
  font-size: 14px;
  font-weight: 300;
}

.dtu-toggle-switch {
  position: relative;
  width: 32px;
  height: 16px;
  border-radius: 10px;
  background: var(--secondaryBackground);
  transition: background 0.2s ease-in-out;

  &::before {
    position: absolute;
    top: 2px;
    right: 18px;
    bottom: 0;
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: white;
    transition: right 0.2s ease-in-out;
    content: '';
  }

  .dtu-active & {
    background: linear-gradient(to bottom right, var(--blue) 0%, var(--purple) 100%);

    &::before {
      right: 2px;
    }
  }
}

.dtu-new-styles {
  .dtu-toggle {
    &.large {
      .dtu-toggle-switch {
        width: 56px;
        height: 36px;

        &::before {
          top: 4px;
          left: 4px;
        }
      }

      &.dtu-active .dtu-toggle-switch::before {
        left: unset;
        right: 4px;
      }
    }

    &.dtu-active .dtu-toggle-switch {
      background: var(--horizontalLinearGradient);

      &::before {
        right: 2px;
      }
    }

    &.dtu-disabled {
      opacity: var(--opacityDisabled);
    }

    .dtu-toggle-switch {
      border: 2px solid var(--border);
      height: 20px;

      &::before {
        right: 14px;
      }
    }
  }
}
