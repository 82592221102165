.dtu-chip-group {
  display: block;
  margin-bottom: 20px;
}

.dtu-chip,
.dtu-chip.mat-standard-chip {
  display: inline-flex;
  align-items: center;
  height: 32px;
  margin: 4px;
  padding: 0 8px 0 12px;
  border-radius: 16px;
  background: var(--secondaryBackground);
  color: var(--text);

  &.mat-chip-selected {
    background: linear-gradient(to bottom right, var(--blue) 0%, var(--purple) 100%);
  }

  .dtu-chip-label {
    margin-right: 8px;
    color: var(--textLight);
    font-size: 12px;
  }

  .mat-chip-remove {
    width: 18px;
    height: 18px;
    margin-left: 8px;
    color: var(--textLight);
    font-size: 18px;
    opacity: 0.4;
    cursor: pointer;
  }
}

.dtu-chip.mat-standard-chip {
  padding: 0 12px;
  cursor: pointer;
}

.dtu-new-styles {
  .dtu-chip,
  .dtu-chip.mat-standard-chip {
    &.mat-chip-selected {
      background: var(--horizontalLinearGradient);
    }
  }
}
