:root {
  .dtu-button-group {
    display: flex;
    align-items: center;
  }

  .dtu-button-list {
    display: flex;
    flex-direction: column;

    .mat-button {
      width: fit-content;
      min-width: 250px;
      margin: 0 0 15px 0;
    }
  }

  .mat-button,
  .mdc-button.mat-mdc-button-base.mat-unthemed {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 80px;
    min-height: 40px;
    margin-right: 15px;
    padding: 0 20px;
    border-radius: 5px;
    color: var(--textLight);
    line-height: 2.5;
    font-size: 1rem;
    font-weight: normal;
    text-decoration: none;
    transition: background-color 0.2s ease-out;

    .mat-button-focus-overlay {
      background: none;
    }

    &:focus:not([disabled]),
    &:hover:not([disabled]) {
      background-color: var(--backgroundHover);
    }

    &:disabled {
      opacity: 0.5;
    }

    &:last-child {
      margin-right: 0;
    }

    &.mat-primary {
      background-image: linear-gradient(to bottom right, var(--blue) 25%, var(--purple) 75%);
      background-position: 50%;
      background-size: 200% 100%;
      color: white;
      transition: background-position 0.2s ease-out;

      &:focus:not([disabled]),
      &:hover:not([disabled]) {
        background-position: 10%;
      }

      .mat-spinner circle {
        stroke: white;
      }
    }

    &.mat-accent {
      background-color: var(--secondaryBackground);
      color: var(--text);

      &:focus:not([disabled]),
      &:hover:not([disabled]) {
        background-color: var(--secondaryBackgroundHover);
      }
    }

    &.mat-tertiary {
      background-image: linear-gradient(120deg, rgba(white, 0.4), rgba(white, 0.2) 50%);
      background-position: 99%;
      background-size: 200% 100%;
      transition: background-position 0.1s ease-out;
      color: var(--white);

      &:focus:not([disabled]),
      &:hover:not([disabled]) {
        background-position: 0;
        background-color: unset;
      }
    }

    &.mat-blue {
      color: var(--blue);

      &:focus:not([disabled]),
      &:hover:not([disabled]) {
        background-color: var(--backgroundHover);
      }
    }

    &.dtu-small-button {
      padding: 0;
      margin-top: 5px;
      min-width: unset;
      min-height: unset;
      font-size: smaller;
      line-height: 1rem;

      &:hover,
      &:focus {
        background: none;
        color: var(--text);
      }
    }

    .dtu-button-primary {
      margin-top: 8px;
      line-height: 22px;
    }

    .dtu-button-secondary {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      line-height: 20px;
      font-size: 0.875rem;
      opacity: 0.5;
    }

    .dtu-label {
      margin-right: 0.875rem;
      font-size: 14px;
    }

    .dtu-value {
      display: inline-flex;
      align-items: center;
      color: var(--text);
    }

    .dtu-value,
    .mat-icon {
      &.dtu-prefix {
        width: 20px;
        height: 20px;
        margin: 0 2px 2px -8px;
        font-size: 20px;
      }

      &.dtu-suffix {
        width: 18px;
        height: 18px;
        margin: 0 -2px;
        font-size: 18px;
        color: var(--textLight);
      }
    }

    .mat-spinner {
      margin: 0 auto;

      circle {
        stroke: var(--text);
      }
    }

    &.dtu-lab-guide-exercise-complete-btn .mat-button-wrapper {
      display: flex;
      align-items: center;
    }
  }

  .mat-icon-button,
  .mdc-icon-button.mat-mdc-button-base.mat-unthemed {
    border-radius: 5px;
    color: var(--textLight);
    transition: background-color 0.2s ease-out;

    .mat-spinner {
      margin: 0 auto;

      circle {
        stroke: var(--text);
      }
    }

    &:focus:not([disabled]),
    &:hover:not([disabled]) {
      background-color: var(--backgroundHover);
    }

    &:disabled {
      opacity: 0.4;
      color: var(--textLight);
    }

    &.mat-accent {
      background-color: var(--secondaryBackground);
      color: var(--text);

      &:focus:not([disabled]),
      &:hover:not([disabled]) {
        background-color: var(--secondaryBackgroundHover);
      }
    }

    &.dtu-small {
      width: 24px;
      height: 24px;
      line-height: 24px;
    }
  }

  a.mat-icon-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dtu-image-header {
    .dtu-header-actions {
      margin-top: 20px;

      .mat-button:not(.mat-primary),
      .mdc-button.mat-mdc-button-base.mat-unthemed:not(.mat-primary) {
        color: rgba(white, 0.6);

        &:focus:not([disabled]),
        &:hover:not([disabled]) {
          background-color: rgba(white, 0.08);
        }
      }
    }
  }
}

.dtu-button-link {
  padding: 0;
  border: none;
  background: none;
  color: inherit;
  font-size: 0.875rem;
  text-decoration: underline;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--textLight);
  }
}

.dtu-favorite {
  &:disabled {
    color: var(--textLight);
  }

  &.dtu-active {
    color: var(--text);

    mat-icon {
      background: linear-gradient(to bottom right, var(--blue) 0%, var(--purple) 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.mat-button-toggle-group {
  width: 100%;
  margin-bottom: 10px;

  .mat-button-toggle {
    box-shadow: none;
    border: 2px solid var(--tertiaryBackground) !important;
    border-right: none !important;
    flex-grow: 1;
    background: none;

    &:hover {
      background-color: var(--backgroundHover);
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 0;
      transition: background-color 0.2s ease-out;
    }

    &:last-of-type {
      border-right: 2px solid var(--tertiaryBackground) !important;
    }

    &.mat-button-toggle-checked {
      &:before {
        background-color: var(--blue);
      }

      .mat-button-toggle-button {
        color: var(--white);
        font-weight: inherit;
      }
    }

    .mat-button-toggle-button {
      color: var(--text);
      font-weight: 300;
    }

    .mat-button-toggle-focus-overlay {
      display: none;
    }
  }

  .mat-button-toggle-appearance-standard.mat-button-toggle-disabled {
    background: var(--background);
  }
}

.dtu-merge-candidate .mat-button-wrapper {
  width: 100%;
}

.dtu-new-styles {
  .dtu-button-group {
    > * {
      margin-right: 15px !important;
    }

    *:last-child {
      margin-right: 0 !important;
    }
  }

  .mat-button,
  .mdc-button.mat-mdc-button-base.mat-unthemed {
    .dtu-light-theme & {
      color: var(--black);
      text-decoration-color: rgba(0, 0, 0, 0.25);

      &:focus:not([disabled]),
      &:hover:not([disabled]) {
        text-decoration-color: var(--black);
      }

      &.mat-accent {
        border: 2px solid var(--black);
        background-color: transparent;
        color: var(--black);

        &:focus:not([disabled]),
        &:hover:not([disabled]) {
          background-color: var(--black);
          color: var(--white);
        }
      }
    }

    .dtu-dark-theme & {
      color: var(--white);
      text-decoration-color: rgba(255, 255, 255, 0.25);

      &:focus:not([disabled]),
      &:hover:not([disabled]) {
        text-decoration-color: var(--white);
      }

      &.mat-accent {
        border: 2px solid var(--white);
        background-color: transparent;
        color: var(--white);

        &:focus:not([disabled]),
        &:hover:not([disabled]) {
          background-color: var(--white);
          color: var(--black);
        }
      }
    }
  }

  .mat-button-base:last-child {
    margin-right: 0;
  }

  .mat-button:not(.mat-calendar-period-button),
  .mdc-button.mat-mdc-button-base.mat-unthemed:not(.mat-calendar-period-button) {
    min-height: 52px;
    text-decoration-line: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 10px;
    transition: background-color 0.2s ease-out;

    &.dtu-menu-item-active,
    &:focus:not([disabled]),
    &:hover:not([disabled]) {
      background: none;
      text-decoration-color: var(--text);
    }

    &:disabled {
      opacity: var(--opacityDisabled);
    }

    &.mat-primary,
    &.mat-accent {
      text-decoration-line: none;
    }

    &.mat-primary {
      background-image: unset; // remove when new styles are permanent
      background-color: var(--palette-primary-500);
      color: white;
      transition: background-color 0.2s ease-out;
      padding: 6px 39px;

      &:focus:not([disabled]),
      &:hover:not([disabled]) {
        background-color: var(--palette-primary-600);
      }

      .mat-spinner circle {
        stroke: white;
      }
    }

    &.mat-accent {
      padding: 4px 35px;
    }

    &.dtu-small-button {
      padding: 6px 15px;
      margin-top: 5px;
      min-width: unset;
      min-height: unset;
      font-size: smaller;
      line-height: 1rem;

      &:hover,
      &:focus {
        background: none;
        color: var(--text);
      }
    }

    .dtu-value {
      display: inline-flex;
      align-items: center;
      color: inherit;
    }
  }

  .mat-icon-button.mat-button-base,
  .mdc-icon-button.mat-mdc-button-base.mat-unthemed {
    line-height: 0;
    border-radius: 8px;
    transition: all 0.2s ease-out;

    &:focus:not([disabled]),
    &:hover:not([disabled]) {
      color: var(--text);
    }

    &.mat-button-disabled {
      opacity: var(--opacityDisabled);
    }
  }

  .dtu-favorite {
    &:disabled {
      color: var(--textLight);
    }

    &.dtu-active {
      color: var(--text);

      mat-icon {
        background: var(--horizontalLinearGradient);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}

.dtu-light-theme,
.dtu-dark-theme {
  .dtu-new-styles .dtu-image-header .dtu-header-actions {
    .mat-button,
    .mdc-button.mat-mdc-button-base.mat-unthemed {
      color: var(--white);
      text-decoration-color: rgba(255, 255, 255, 0.25);

      &:focus:not([disabled]),
      &:hover:not([disabled]) {
        background: none; // remove when new styles are permanent
        text-decoration-color: var(--white);
      }

      &.mat-primary {
        background-color: var(--white);
        color: var(--black);
        transition: background-color 0.2s ease-out;

        .mat-spinner circle {
          stroke: var(--black);
        }

        &:focus:not([disabled]),
        &:hover:not([disabled]) {
          background-color: var(--palette-neutral-100);
        }
      }
    }
  }
}
