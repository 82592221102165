:root {
  .mat-datepicker-content {
    border-radius: 5px;
    background-color: var(--secondaryBackground);
    color: var(--text);
  }

  .mat-datepicker-content .mat-calendar {
    height: 100%;
  }

  .mat-calendar-table-header,
  .mat-date-range-input-separator,
  .mat-datepicker-content .mat-mdc-button.mat-unthemed {
    color: var(--textLight);
  }

  .mat-calendar-body-label,
  .mat-calendar-body-cell-content {
    color: var(--text);
  }

  .mat-calendar-arrow {
    fill: var(--textLight);
  }

  .mat-calendar-body-cell-content {
    border-radius: 5px;
  }

  .mat-calendar-body-today:not(.mat-calendar-body-selected) {
    border-color: var(--textLight);
  }

  .mat-calendar-body-selected {
    border-color: var(--secondaryBackground);
    background: linear-gradient(120deg, var(--blue) 0%, var(--purple) 100%);
    color: white;
  }

  .mat-date-range-input-container {
    margin-left: 15px;
  }

  .mat-calendar-body-in-preview {
    color: var(--blue);
  }

  .dtu-new-styles {
    .mat-datepicker-content,
    .mat-calendar-body-cell-content {
      border-radius: var(--borderRadius);
    }

    .mat-calendar-table-header,
    .mat-calendar-table-header th,
    .mat-date-range-input-separator,
    .mat-datepicker-content .mat-mdc-button.mat-unthemed,
    .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
      color: var(--textLight);
    }

    .mat-calendar-body-selected {
      background: var(--horizontalLinearGradient);
    }
  }
}
