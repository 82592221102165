@font-face {
  font-display: swap;
  font-family: 'BerninaSans';
  font-style: normal;
  font-weight: 300;
  src: url('/fonts/BerninaSans-Web-Light.woff2') format('woff2'), url('/fonts/BerninaSans-Web-Light.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'BerninaSans';
  font-style: normal;
  font-weight: 500;
  src: url('/fonts/BerninaSans-Web-Regular.woff2') format('woff2'), url('/fonts/BerninaSans-Web-Regular.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'BerninaSans';
  font-style: bold;
  font-weight: 700;
  src: url('/fonts/BerninaSans-Web-Semibold.woff2') format('woff2'), url('/fonts/BerninaSans-Web-Semibold.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'VeraMono';
  font-style: normal;
  font-weight: 500;
  src: url('/fonts/VeraMono-Web-Regular.woff2') format('woff2');
}
