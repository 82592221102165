.dtu-conflict {
  .dtu-conflict-title {
    margin-bottom: 5px;
  }

  .dtu-conflict-details {
    display: flex;
    position: relative;
    align-items: center;
    margin-top: 20px;
    padding-left: 15px;
    padding-top: 2px;
    padding-bottom: 2px;

    .dtu-conflict-details-label {
      margin-right: 10px;
      font-weight: normal;
    }

    .dtu-conflict-details-info {
      flex-grow: 2;

      .dtu-conflict-details-info-secondary {
        margin-top: 5px;
        font-size: 0.875em;
        font-weight: 300;
        color: var(--textLight);
      }
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: -4px;
      height: 100%;
      width: 4px;
      border-radius: 5px;
    }
  }

  &.warning {
    .dtu-conflict-details::after {
      border: 2px solid var(--yellow);
    }
  }

  &.error {
    .dtu-conflict-details::after {
      border: 2px solid var(--red);
    }
  }
}

.dtu-new-styles {
  .dtu-conflict .dtu-conflict-details::after {
    border-radius: var(--borderRadius);
  }
}
