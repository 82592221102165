.dtu-stepper {
  position: relative;
  margin-top: 25px;

  &:before {
    content: '';
    position: absolute;
    width: 4px;
    top: 35px;
    left: 16px;
    bottom: 0;
    background-color: var(--secondaryBackground);
    z-index: -999;
  }
}

.dtu-step {
  position: relative;
  display: flex;

  &:last-of-type:before {
    content: '';
    position: absolute;
    width: 4px;
    top: 35px;
    left: 16px;
    bottom: 0;
    background-color: var(--background);
    z-index: -999;
  }

  .dtu-step-container {
    width: 100%;
    padding: 20px;
    margin: 0 0 40px 0;
    background: none;

    .dtu-step-title {
      height: auto;
      margin-bottom: 4px;
      line-height: 1.4;
      font-size: 1rem;
      font-weight: bold;
    }

    .dtu-step-content {
      margin-top: 15px;
    }
  }
}

.dtu-step-indicator {
  position: relative;
  min-width: 36px;
  width: 36px;
  height: 36px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--tertiaryBackground);
  border-radius: 50%;
  border: 3px solid var(--background);
  margin-top: 15px;

  p {
    margin-bottom: 0;
  }

  &.dtu-step-indicator-complete {
    background: var(--green);
  }

  &.dtu-step-indicator-active {
    background-image: linear-gradient(to bottom right, var(--blue) 25%, var(--purple) 75%);
  }

  .dtu-step-indicator-number {
    color: var(--white);
  }

  .dtu-step-indicator-icon {
    font-size: 24px;
    color: var(--white);
  }
}

.dtu-new-styles {
  .dtu-step-indicator {
    &.dtu-step-indicator-complete {
      background: var(--palette-positive-500);
    }

    &.dtu-step-indicator-active {
      background: var(--palette-primary-500);
    }
  }
}
