$palettes: (
  primary: (
    200: #ebf1ff,
    250: #d6e4ff,
    300: #adc9ff,
    350: #85adff,
    400: #5c92ff,
    450: #3377ff,
    500: #1966ff,
    550: #0052f5,
    600: #004be0,
    650: #003db8,
    700: #00308f,
    750: #002266,
    800: #00143d,
  ),
  secondary: (
    200: #f3ebff,
    250: #e8d6ff,
    300: #dcc2ff,
    350: #c599ff,
    400: #ae70ff,
    450: #9747ff,
    500: #7f1aff,
    550: #6100e0,
    600: #5000b8,
    650: #3e008f,
    700: #35007a,
    750: #2c0066,
    800: #230052,
  ),
  positive: (
    200: #ebfef9,
    250: #c2feec,
    300: #9afee0,
    350: #5dfdcd,
    400: #0dfdb5,
    450: #02f2aa,
    500: #02d394,
    550: #02b680,
    600: #018d63,
    650: #016547,
    700: #013d2b,
    750: #01281c,
    800: #01140e,
  ),
  warning: (
    200: #fff1c1,
    300: #ffe99e,
    400: #ffd855,
    500: #fdc300,
    600: #e0ac00,
    700: #be9200,
    800: #917000,
  ),
  error: (
    200: #fcc6ce,
    300: #f99fae,
    400: #f7748a,
    500: #f43d5a,
    600: #e60d31,
    700: #c20b29,
    800: #90081f,
  ),
  neutral: (
    20: #f5f5f5,
    50: #ebebeb,
    100: #d6d6d6,
    200: #c2c2c2,
    300: #adadad,
    400: #a3a3a3,
    500: #999999,
    600: #858585,
    700: #707070,
    800: #525252,
    900: #333333,
    950: #141414,
    980: #000000,
  ),
  ui: (
    100: #3d4e7b,
    300: #2d3a5b,
    500: #1a2440,
    700: #0b152b,
  ),
);

@function toRGB($color) {
  @return red($color) + ', ' + green($color) + ', ' + blue($color);
}

:root {
  --white: #ffffff;
  --black: #000000;
  --blue: #2583ee;
  --blueLight: rgba(#{toRGB(#2583ee)}, 0.2);
  --blueRGB: #{toRGB(#2583ee)};
  --purple: #712f90;
  --purpleRGB: #{toRGB(#712f90)};
  --green: #2ecc71;
  --red: #e74d59;
  --yellow: #e0ac00;
  --darkBackground: #02091b;
  --darkBackgroundRGB: #{toRGB(#02091b)};
}

.dtu-light-theme {
  --text: var(--black);
  --textLight: #6e767f;

  --background: white;
  --backgroundHover: #f5f6f9;
  --backgroundHoverLight: #fafafc;
  --backgroundOverlay: rgba(255, 255, 255, 0.8);
  --secondaryBackgroundOverlay: rgba(255, 255, 255, 0.8);
  --secondaryBackgroundOverlayLight: rgba(#{toRGB(#98a0ab)}, 0.1);
  --secondaryBackgroundOverlayMedLight: rgba(#{toRGB(#98a0ab)}, 0.15);
  --secondaryBackgroundOverlayMed: rgba(#{toRGB(#98a0ab)}, 0.2);
  --secondaryBackground: #f5f6f9;
  --secondaryBackgroundHover: #eceef3;
  --tertiaryBackground: #cbcfd5;
  --codeBackground: #23282d;
  --codeBackgroundHover: #2c3035;
  --tileBackground: white;
  --tooltipBackground: #171b20;
  --thumbnailBackground: rgba(0, 0, 0, 0.8);
  --tileThumbnailLoading: var(--white);

  --border: #f5f6f9;

  --boxShadowColor: rgba(#{toRGB(#2b3d59)}, 0.15);
  --boxShadowColorHover: rgba(#{toRGB(#2b3d59)}, 0.25);

  --loadingAnimation: rgba(255, 255, 255, 0.8);
}

.dtu-dark-theme {
  --text: white;
  --textLight: #98a0ab;

  --background: #171b20;
  --backgroundHover: #23282d;
  --backgroundHoverLight: #1d2127;
  --backgroundOverlay: rgba(#{toRGB(#171b20)}, 0.8);
  --secondaryBackgroundOverlay: rgba(#{toRGB(#23282d)}, 0.8);
  --secondaryBackgroundOverlayLight: rgba(#{toRGB(#23282d)}, 0.2);
  --secondaryBackgroundOverlayMedLight: rgba(#{toRGB(#23282d)}, 0.5);
  --secondaryBackgroundOverlayMed: rgba(#{toRGB(#23282d)}, 1);
  --secondaryBackground: #23282d;
  --secondaryBackgroundHover: #2c3035;
  --tertiaryBackground: #515458;
  --codeBackground: #23282d;
  --codeBackgroundHover: #2c3035;
  --tileBackground: #23282d;
  --tooltipBackground: #23282d;
  --thumbnailBackground: rgba(0, 0, 0, 0.8);
  --tileThumbnailLoading: #171b20;

  --border: #23282d;

  --boxShadowColor: rgba(0, 0, 0, 0.15);
  --boxShadowColorHover: rgba(0, 0, 0, 0.35);

  --loadingAnimation: rgba(255, 255, 255, 0.04);
}

.dtu-new-styles {
  :root & {
    @each $colorname, $palette in $palettes {
      @each $key, $value in $palette {
        $keyname: '--palette-' + $colorname + '-' + $key;
        #{$keyname}: #{$value};
      }
    }

    --blue: var(--palette-primary-500);
    --blueLight: rgba(#{toRGB(#1966ff)}, 0.25);
    --blueRGB: #{toRGB(#1966ff)};
    --purple: var(--palette-secondary-500);
    --purpleRGB: #{toRGB(#7f1aff)};
    --green: var(--palette-positive-500);
    --red: var(--palette-error-500);
    --yellow: var(--palette-warning-500);
    --horizontalLinearGradient: linear-gradient(93deg, var(--purple) 0%, var(--palette-primary-550) 52.08%, var(--green) 100%);
    --verticalLinearGradient: linear-gradient(0deg, var(--purple) 0%, var(--palette-primary-550) 45.92%, var(--green) 95.83%);
  }

  .dtu-light-theme & {
    --secondaryBackground: var(--palette-neutral-50);
    --secondaryBackgroundHover: var(--palette-neutral-100);
    --tertiaryBackground: var(--palette-neutral-200);
    --formBackground: var(--background);
    --thumbBackground: var(--palette-neutral-500);

    --border: var(--palette-neutral-200);

    --boxShadow: var(--dropshadow-light-10);
    --boxShadowHover: var(--dropshadow-light-20);
  }

  .dtu-dark-theme & {
    --formBackground: var(--background);
    --thumbBackground: var(--palette-neutral-900);

    --border: var(--palette-neutral-700);

    --boxShadow: var(--dropshadow-dark-10);
    --boxShadowHover: var(--dropshadow-dark-20);
  }
}
