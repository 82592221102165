.dtu-table-container {
  position: relative;

  &:empty {
    height: 370px;
    background-color: unset;
    background-image: linear-gradient(var(--secondaryBackground) 18px, transparent 0),
      linear-gradient(var(--secondaryBackground) 18px, transparent 0), linear-gradient(var(--secondaryBackground) 18px, transparent 0),
      linear-gradient(var(--secondaryBackground) 18px, transparent 0), linear-gradient(var(--secondaryBackground) 2px, transparent 0),
      linear-gradient(var(--secondaryBackground) 20px, transparent 0), linear-gradient(var(--secondaryBackground) 20px, transparent 0),
      linear-gradient(var(--secondaryBackground) 20px, transparent 0), linear-gradient(var(--secondaryBackground) 20px, transparent 0),
      linear-gradient(var(--secondaryBackground) 2px, transparent 0), linear-gradient(var(--secondaryBackground) 20px, transparent 0),
      linear-gradient(var(--secondaryBackground) 20px, transparent 0), linear-gradient(var(--secondaryBackground) 20px, transparent 0),
      linear-gradient(var(--secondaryBackground) 20px, transparent 0), linear-gradient(var(--secondaryBackground) 2px, transparent 0),
      linear-gradient(var(--secondaryBackground) 20px, transparent 0), linear-gradient(var(--secondaryBackground) 20px, transparent 0),
      linear-gradient(var(--secondaryBackground) 20px, transparent 0), linear-gradient(var(--secondaryBackground) 20px, transparent 0),
      linear-gradient(var(--secondaryBackground) 2px, transparent 0), linear-gradient(var(--secondaryBackground) 20px, transparent 0),
      linear-gradient(var(--secondaryBackground) 20px, transparent 0), linear-gradient(var(--secondaryBackground) 20px, transparent 0),
      linear-gradient(var(--secondaryBackground) 20px, transparent 0), linear-gradient(var(--secondaryBackground) 2px, transparent 0);
    background-size: 20% 18px, 20% 18px, 20% 18px, 20% 18px, 100% 2px, 20% 20px, 20% 20px, 20% 20px, 20% 20px, 100% 2px, 20% 20px, 20% 20px,
      20% 20px, 20% 20px, 100% 2px, 20% 20px, 20% 20px, 20% 20px, 20% 20px, 100% 2px, 20% 20px, 20% 20px, 20% 20px, 20% 20px, 100% 2px;
    background-position: 0 15px, 35% 15px, 70% 15px, 105% 15px, 0 48px, 0 79px, 35% 79px, 70% 79px, 105% 79px, 0 128px, 0 159px, 35% 159px,
      70% 159px, 105% 159px, 0 208px, 0 236px, 35% 236px, 70% 236px, 105% 236px, 0 288px, 0 319px, 35% 319px, 70% 319px, 105% 319px, 0 368px;
    background-repeat: no-repeat;
  }
}

.dtu-table-selector {
  .dtu-table .mat-row {
    cursor: pointer;
  }
}

.dtu-table-loading {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  z-index: 101;
  padding: 80px 40px;
  background: var(--backgroundOverlay);
}

.dtu-table-header {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  justify-content: space-between;

  .dtu-table-title {
    margin-right: 20px;

    h2 {
      margin: 0;
    }
  }

  .dtu-table-filters {
    flex-grow: 2;
    margin-right: 20px;
  }

  .dtu-table-actions {
    display: flex;
    align-items: center;

    button {
      margin-right: 15px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

.dtu-table-filters {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 5px;

  .dtu-table-search {
    width: 100%;
    max-width: 500px;

    &.dtu-form-group {
      margin: 0;
    }

    &:empty {
      height: 50px;
      border-radius: 5px;
      background-color: var(--secondaryBackground);
    }

    .mat-icon-button {
      &:focus:not([disabled]),
      &:hover:not([disabled]) {
        background: transparent;
      }
    }
  }

  .dtu-table-search-filters {
    display: flex;
    align-items: center;
    max-width: 600px;

    .dtu-table-search-options {
      margin-left: 15px;
    }
  }

  .dtu-filter-count {
    min-width: 40px;
    margin: 0 -2px 0 6px;
    padding: 0 10px;
    border-radius: 5px;
    font-size: 0.875rem;
    background-color: var(--secondaryBackground);
    color: var(--text);
    text-align: center;

    &.active {
      background-color: var(--blue);
      color: white;
    }
  }

  .dtu-table-options {
    display: flex;
    align-items: center;
    margin: 5px 0;

    button {
      margin-right: 15px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

.dtu-table-wrapper {
  padding-bottom: 10px;
  overflow: auto;

  &:empty {
    height: 370px;
    background-color: unset;
    background-image: linear-gradient(var(--secondaryBackground) 18px, transparent 0),
      linear-gradient(var(--secondaryBackground) 18px, transparent 0), linear-gradient(var(--secondaryBackground) 18px, transparent 0),
      linear-gradient(var(--secondaryBackground) 18px, transparent 0), linear-gradient(var(--secondaryBackground) 2px, transparent 0),
      linear-gradient(var(--secondaryBackground) 20px, transparent 0), linear-gradient(var(--secondaryBackground) 20px, transparent 0),
      linear-gradient(var(--secondaryBackground) 20px, transparent 0), linear-gradient(var(--secondaryBackground) 20px, transparent 0),
      linear-gradient(var(--secondaryBackground) 2px, transparent 0), linear-gradient(var(--secondaryBackground) 20px, transparent 0),
      linear-gradient(var(--secondaryBackground) 20px, transparent 0), linear-gradient(var(--secondaryBackground) 20px, transparent 0),
      linear-gradient(var(--secondaryBackground) 20px, transparent 0), linear-gradient(var(--secondaryBackground) 2px, transparent 0),
      linear-gradient(var(--secondaryBackground) 20px, transparent 0), linear-gradient(var(--secondaryBackground) 20px, transparent 0),
      linear-gradient(var(--secondaryBackground) 20px, transparent 0), linear-gradient(var(--secondaryBackground) 20px, transparent 0),
      linear-gradient(var(--secondaryBackground) 2px, transparent 0), linear-gradient(var(--secondaryBackground) 20px, transparent 0),
      linear-gradient(var(--secondaryBackground) 20px, transparent 0), linear-gradient(var(--secondaryBackground) 20px, transparent 0),
      linear-gradient(var(--secondaryBackground) 20px, transparent 0), linear-gradient(var(--secondaryBackground) 2px, transparent 0);
    background-size: 20% 18px, 20% 18px, 20% 18px, 20% 18px, 100% 2px, 20% 20px, 20% 20px, 20% 20px, 20% 20px, 100% 2px, 20% 20px, 20% 20px,
      20% 20px, 20% 20px, 100% 2px, 20% 20px, 20% 20px, 20% 20px, 20% 20px, 100% 2px, 20% 20px, 20% 20px, 20% 20px, 20% 20px, 100% 2px;
    background-position: 0 15px, 35% 15px, 70% 15px, 105% 15px, 0 48px, 0 79px, 35% 79px, 70% 79px, 105% 79px, 0 128px, 0 159px, 35% 159px,
      70% 159px, 105% 159px, 0 208px, 0 236px, 35% 236px, 70% 236px, 105% 236px, 0 288px, 0 319px, 35% 319px, 70% 319px, 105% 319px, 0 368px;
    background-repeat: no-repeat;
  }
}

.dtu-table-full-width {
  table {
    min-width: auto;
  }
}

.dtu-table {
  width: 100%;
  min-width: 980px;
  background: none;

  &.mat-table {
    .mat-header-row {
      height: 50px;
    }

    .mat-header-cell {
      padding: 0 15px;
      border-bottom: 1px solid var(--border);
      background-color: var(--background);
      color: var(--text);
      font-size: 0.875rem;

      .mat-sort-header-arrow {
        color: var(--blue);
      }
    }

    .mat-row {
      height: 80px;

      &.dtu-expandable-row {
        cursor: pointer;

        .dtu-checkbox {
          margin-bottom: 6px;
        }

        .mat-column-event {
          max-width: 400px;
          padding-right: 50px;
        }

        .mat-column-caret {
          text-align: right;
          color: var(--textLight);
        }

        td {
          border-bottom-width: 0;
        }
      }

      &.dtu-expandable-detail-row {
        height: 0;
        cursor: auto;

        .dtu-expandable-detail {
          overflow: hidden;
          display: flex;
          padding-left: 59px;

          .dtu-expandable-detail-content {
            padding: 15px 0;

            .dtu-expandable-title {
              font-weight: bold;
              margin-bottom: 10px;
            }

            .dtu-expandable-description {
              font-weight: 300;
            }
          }
        }

        .mat-cell {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }

    .mat-cell {
      padding: 10px 15px;
      border-bottom: 1px solid var(--border);
      background-color: var(--background);
      color: var(--text);
      font-size: 1rem;

      .dtu-secondary-value {
        font-size: 0.875rem;
        color: var(--textLight);
      }

      .dtu-link {
        color: var(--blue);
      }

      .dtu-empty-value {
        color: var(--textLight);
      }
    }

    .dtu-cell-nowrap {
      white-space: nowrap;
    }

    .dtu-cell-right {
      text-align: right;
    }
  }

  &.dtu-table-select {
    .mat-row {
      cursor: pointer;

      &:focus,
      &:hover {
        .mat-cell {
          background-color: var(--backgroundHoverLight);
          transition: background-color 0.2s ease-in-out;
        }
      }
    }
  }

  &.dtu-table-no-headers {
    thead {
      display: none;
    }

    tr:first-of-type .mat-cell {
      border-top: 1px solid var(--border);
    }
  }
}

.dtu-table-empty {
  padding: 20px 0;
  color: var(--textLight);
}

.dtu-table-pagination {
  margin-top: 15px;
  background-color: var(--background);
  color: var(--textLight);
  font-size: 1rem;

  .mat-paginator-container {
    min-height: 40px;
    padding: 0;
  }

  .mat-paginator-range-label {
    margin: 0 15px 0 0;
  }
}

.cdk-drag {
  cursor: move;

  &.cdk-drag-preview {
    opacity: 0;
  }
}

table:not(.dtu-table) {
  min-width: 100%;
  border-collapse: collapse;
  margin-bottom: 16px;

  th {
    color: var(--textLight);
    font-weight: 300;
    font-size: 0.875rem;
  }

  th,
  td {
    padding: 15px;
    text-align: left;
  }

  tr {
    border-bottom: 1px solid var(--border);
  }

  td.dtu-sticky-end {
    text-align: right;
  }
}

.dtu-new-styles {
  .dtu-table-filters {
    .dtu-table-search:empty,
    .dtu-table-search-filters .dtu-filter-count {
      border-radius: var(--borderRadius);
    }
  }

  table:not(.dtu-table):not(.mat-calendar-table) {
    min-width: 100%;
    border-collapse: collapse;
    margin-bottom: 16px;

    th {
      color: var(--textLight);
      font-weight: 300;
      font-size: 0.875rem;
    }

    th,
    td {
      padding: 15px;
      text-align: left;
    }

    tr {
      border-bottom: 1px solid var(--border);
    }

    td.dtu-sticky-end {
      text-align: right;
    }
  }
}
