// Import Angular Material theming
@use '@angular/material' as mat;
@import '@angular/material/theming';

// Set Angular Material typography
$dtu-typography: mat.define-typography-config(
  $font-family: 'BerninaSans, sans-serif',
);

// Set Angular Material theme
@include mat.core();
@include mat.legacy-core();

$dtu-ui-primary: mat.define-palette($mat-indigo);
$dtu-ui-accent: mat.define-palette($mat-pink, A200, A100, A400);
$dtu-ui-warn: mat.define-palette($mat-red);
$dtu-ui-theme: mat.define-light-theme(
  (
    color: (
      primary: $dtu-ui-primary,
      accent: $dtu-ui-accent,
      warn: $dtu-ui-warn,
    ),
    typography: $dtu-typography,
  )
);

@include mat.all-component-themes($dtu-ui-theme);
@include mat.all-legacy-component-themes($dtu-ui-theme);

// Import highlight theming
@import 'highlight.js/scss/night-owl.scss';

// Import application styling
@import 'layout/breakpoints';
@import 'layout/flex';
@import 'layout/grid';
@import 'themes/palette';
@import 'base/constants';
@import 'base/fonts';
@import 'base/typography';
@import 'components/accordion';
@import 'components/announcement';
@import 'components/aside';
@import 'components/autocomplete';
@import 'components/banner';
@import 'components/button';
@import 'components/calendar';
@import 'components/ceus';
@import 'components/chart';
@import 'components/checkbox';
@import 'components/chip';
@import 'components/conflict';
@import 'components/dialog';
@import 'components/empty';
@import 'components/filter';
@import 'components/form';
@import 'components/header';
@import 'components/lab-guide';
@import 'components/list';
@import 'components/menu';
@import 'components/progress-bar';
@import 'components/recently-viewed';
@import 'components/resource';
@import 'components/section';
@import 'components/slider';
@import 'components/selector';
@import 'components/snack-bar';
@import 'components/spinner';
@import 'components/stepper';
@import 'components/table';
@import 'components/table-components';
@import 'components/table-of-contents';
@import 'components/tabs';
@import 'components/tile';
@import 'components/toggle';
@import 'components/tooltip';
@import 'components/video';

*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: var(--secondaryBackground);
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: var(--tertiaryBackground);
}

html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  margin: 0;
  background-color: var(--background);
  color: var(--text);
}

img {
  max-width: 100%;
}

hr {
  height: 2px;
  margin: 0 0 50px 0;
  border: none;
  border-radius: 2px;
  background-color: var(--border);
}

small {
  font-size: 0.75rem;
}

.dtu-hidden {
  display: none;
}

.dtu-selection-disabled {
  user-select: none;
}

.dtu-wrapper {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding-right: 1.25rem;
  padding-left: 1.25rem;

  @media only screen and (min-width: $dtu-bp-sm) {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }

  &.dtu-fluid-wrapper {
    max-width: unset;
    margin: 0;
  }
}

.dtu-global-nav {
  border-bottom: 4px solid var(--border);

  .dtu-new-styles & {
    .dtu-light-theme & {
      border-bottom: 4px solid var(--palette-neutral-50);
    }

    .dtu-dark-theme & {
      border-bottom: 4px solid var(--palette-neutral-900);
    }
  }
}

.dtu-metadata-group {
  margin-bottom: 20px;

  .dtu-metadata-group-label {
    margin-bottom: 10px;
    font-weight: bold;
  }
}

.dtu-metadata-inline {
  display: flex;
  flex-flow: row wrap;

  .dtu-metadata {
    margin-right: 60px;
  }
}

.dtu-metadata {
  margin-bottom: 20px;

  .dtu-metadata-label:empty,
  .dtu-metadata-value:empty {
    border-radius: 5px;
    background-color: var(--secondaryBackground);
    background-image: linear-gradient(90deg, transparent 0, var(--loadingAnimation) 50%, transparent 100%);
    background-size: 1000px 100%;
    background-position: -1000px 0;
    background-repeat: no-repeat;
    animation: metadataShimmer 4s infinite;
    @keyframes metadataShimmer {
      0% {
        background-position: -1000px 0;
      }
      100% {
        background-position: 1000px 0;
      }
    }
  }

  .dtu-metadata-label {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 300;
    color: var(--textLight);

    &:empty {
      width: 60px;
      height: 18px;
    }
  }

  .dtu-metadata-value {
    display: flex;
    align-items: center;
    overflow-wrap: anywhere;

    &.dtu-metadata-value-block {
      display: block;
    }

    &:empty {
      width: 140px;
      height: 20px;
    }

    small {
      margin: 2px 0 0 4px;
    }

    .dtu-metadata-value-secondary {
      line-height: 18px;
      font-size: 0.875rem;
      color: var(--textLight);
    }
  }

  .dtu-metadata-info {
    width: 18px;
    height: 18px;
    margin-left: 6px;
    font-size: 18px;
    color: var(--textLight);
    opacity: 0.6;
  }

  .dtu-metadata-datetime {
    .dtu-metadata-time {
      font-size: 0.875rem;
      color: var(--textLight);
    }
  }

  &.dtu-metadata-editable {
    .dtu-form-field {
      .mat-input-element {
        padding: 2px 10px;
      }

      .mat-form-field-infix {
        width: 65px;
      }

      .mat-form-field-suffix {
        padding: 0;
        flex: 0;
        display: flex;
        align-items: center;

        .mat-icon-button {
          margin-left: 0;
        }
      }
    }

    .dtu-metadata-value {
      border-radius: 5px;

      > .mat-button {
        min-height: unset;
        min-width: unset;
        padding: 4px 6px;
        margin-top: -4px;
        margin-left: -6px;
        line-height: 1;
        border: 2px solid var(--background);
        color: var(--text);

        .mat-button-wrapper {
          display: flex;
          align-items: center;
          line-height: 1;
        }

        &:hover {
          background: var(--background);
          border-color: var(--secondaryBackground);
        }

        .mat-icon {
          visibility: hidden;
          height: 14px;
          width: 14px;
          line-height: 14px;
          font-size: 14px;
          margin-left: 6px;
          color: var(--textLight);
        }
      }

      &:hover > .mat-button .mat-icon {
        visibility: visible;
      }
    }

    &.editing .dtu-metadata-value {
      display: none;
    }

    &:not(.editing) .dtu-metadata-value-edit {
      display: none;
    }
  }
}

.dtu-attribute-group {
  margin-bottom: 25px;
  padding: 20px;
  border: 2px solid var(--border);
  border-radius: 5px;
}

.dtu-attribute {
  margin-bottom: 12px;

  .dtu-attribute-label {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 300;
    color: var(--textLight);
  }

  .dtu-attribute-content {
    display: flex;
    align-items: center;
    height: 32px;
  }

  .dtu-attribute-value {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .dtu-attribute-actions {
    display: flex;
    margin-left: 5px;

    .mat-icon-button {
      width: 32px;
      height: 32px;
      line-height: 32px;
      opacity: 0;
      transition: opacity 0.2s ease-out;
    }

    .mat-icon {
      width: 20px;
      height: 20px;
      line-height: 1;
      font-size: 20px;
    }
  }

  .dtu-attribute-content .mat-icon-button:focus,
  .dtu-attribute-content:hover .mat-icon-button {
    opacity: 1;
  }
}

.dtu-tag-group {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
}

.dtu-tag {
  border-radius: 20px;
  background: var(--secondaryBackground);
  padding: 7px 15px;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin: 5px;
}

.dtu-tag .mat-button-wrapper {
  display: flex;
  justify-content: center;
}

.dtu-schedule {
  .dtu-schedule-row {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .dtu-schedule-info {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
    }

    .dtu-schedule-date,
    .dtu-schedule-time {
      margin: 0 0 0 10px;
    }

    .dtu-schedule-date {
      font-weight: 500;
    }

    .dtu-schedule-time {
      margin-top: 2px;

      &:not(.dtu-multiple-slots) {
        text-transform: lowercase;
      }
    }

    mat-icon {
      background: linear-gradient(to bottom right, var(--blue) 0%, var(--purple) 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .dtu-schedule-date,
    .dtu-schedule-time {
      //padding-left: 10px;
      line-height: 1.3;
    }

    .dtu-schedule-time {
      font-size: 14px;
      color: var(--textLight);
      padding-right: 0;
    }

    .dtu-schedule-date-connector {
      font-size: 14px;
      margin-top: 3px;
      margin-left: 3px;
      margin-bottom: 0;
      line-height: 1.3;
    }
  }
}

.dtu-user {
  display: flex;
  align-items: center;
}

.dtu-user-profile {
  position: relative;
  width: 36px;
  height: 36px;
  border-radius: 5px;
  line-height: 36px;
  text-align: center;
  text-transform: uppercase;
  font-size: 1rem;
  background-color: var(--secondaryBackground);
  color: var(--text);

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 36px;
    height: 36px;
    border-radius: 5px;
  }
}

.dtu-user-details {
  margin-left: 12px;
}

.dtu-user-type {
  position: absolute;
  right: -5px;
  bottom: -5px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid var(--background);
  line-height: 15px;
  font-size: 10px;
  text-align: center;
  text-transform: uppercase;
  background-color: var(--blue);
  color: white;

  &.customer {
    background-color: var(--blue);
  }

  &.employee {
    background-color: var(--green);
  }

  &.partner {
    background-color: var(--purple);
  }
}

.dtu-user-details {
  flex-shrink: 2;
  min-width: 0;
  padding: 1px 0;
}

.dtu-metadata-value-secondary,
.dtu-user-email {
  line-height: 18px;
  font-size: 0.875rem;
  color: var(--textLight);
}

.dtu-status {
  display: inline-block;
  padding: 0 10px;
  border-radius: 12px;
  line-height: 20px;
  font-size: 0.75rem;
  white-space: nowrap;
  background-color: var(--secondaryBackground);
  color: var(--text);

  &.active,
  &.complete,
  &.passed,
  &.running {
    background-color: var(--green);
    color: white;
  }

  &.failed,
  &.stopped,
  &.error {
    background-color: var(--red);
    color: white;
  }

  &.future,
  &.primary {
    background-color: var(--blue);
    color: white;
  }

  &.starting {
    background-color: var(--purple);
    color: white;
  }
}

.dtu-payment {
  display: flex;
  line-height: 21px;

  .dtu-payment-method {
    width: 35px;
    height: 21px;
    margin-right: 10px;
    border-radius: 2px;
    background-position: 0 center;
    background-size: 245px 21px;
    background-repeat: no-repeat;
  }
}

.dtu-superscript {
  position: relative;
  bottom: 0.3em;
  font-size: 0.83em;
  color: var(--textLight);
}

.ql-toolbar.ql-snow {
  .ql-header.ql-picker {
    .ql-picker-label {
      border-radius: 5px 5px 0 0;
      color: var(--textLight);

      &:hover,
      &.ql-active {
        color: var(--text);

        .ql-stroke {
          stroke: var(--text);
        }
      }
    }
  }
}

.dtu-completion {
  position: relative;
  display: flex;
  align-items: center;

  .mat-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    font-size: 16px;

    margin-right: 10px;
    border-radius: 5px;
    background: var(--secondaryBackground);
    color: var(--white);
  }

  &.not-started .mat-icon {
    color: var(--text);
  }

  &.expired .mat-icon {
    background: var(--yellow);
  }

  &.failed .mat-icon {
    background: var(--red);
  }

  &.in-progress .mat-icon {
    background: var(--blue);
  }

  &.complete .mat-icon {
    background: var(--green);
  }
}

.dtu-new-styles {
  .dtu-schedule .dtu-schedule-row mat-icon {
    background: var(--horizontalLinearGradient);
    background-clip: text;
    -webkit-background-clip: text;
  }

  .ql-toolbar.ql-snow .ql-header.ql-picker .ql-picker-label {
    border-radius: var(--borderRadius) var(--borderRadius) 0 0;
  }

  .dtu-metadata .dtu-metadata-label:empty,
  .dtu-metadata .dtu-metadata-value:empty,
  .dtu-metadata.dtu-metadata-editable .dtu-metadata-value,
  .dtu-attribute-group,
  .dtu-user-profile,
  .dtu-user-profile img,
  .dtu-complete .mat-icon {
    border-radius: var(--borderRadius);
  }
}
