.dtu-banner {
  display: block;
  margin-top: 40px;
}

.dtu-banner-image {
  border-radius: 5px;
  box-shadow: 0 0px 8px 0 var(--text);
}

.dtu-new-styles .dtu-banner-image {
  border-radius: var(--borderRadius);
}
