.dtu-toc-container {
  width: 100%;
  justify-content: center;
  position: sticky;
  margin-left: 80px;
  margin-bottom: 20px;
  top: 100px;
}

.dtu-table-of-contents::before {
  position: absolute;
  width: 4px;
  height: 100%;
  border: 2px solid var(--secondaryBackground);
  border-radius: 5px;
  content: '';
}

.dtu-toc-section {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 30px;
  margin-bottom: 10px;
  padding-left: 10px;
  text-decoration: none;

  &::before {
    margin-right: 5px;
    position: relative;
    top: -2px;
    left: -10px;
    width: 4px;
    height: 25px;
    border: 2px solid var(--secondaryBackground);
    border-radius: 5px;
    content: '';
  }

  &:focus,
  &:hover {
    color: var(--text);
  }

  &:hover {
    cursor: pointer;

    &::before {
      border: unset;
      display: inline-block;
      position: relative;
      top: -2px;
      left: -10px;
      width: 4px;
      height: 25px;
      background: linear-gradient(to bottom right, var(--blue) 0%, var(--purple) 100%);
      border-radius: 5px;
      content: '';
    }
  }
}

.dtu-new-styles {
  .dtu-table-of-contents::before,
  .dtu-toc-section::before,
  .dtu-toc-section:hover::before {
    border-radius: var(--borderRadius);
  }

  .dtu-toc-section:hover::before {
    background: var(--verticalLinearGradient);
  }
}
