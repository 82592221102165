form {
  width: 100%;
}

.dtu-form-error {
  margin-bottom: 15px;
  color: var(--red);
}

.dtu-form-section-spacer {
  margin-bottom: 25px;
  text-align: center;
  color: var(--textLight);
}

.dtu-form-section {
  margin-bottom: 25px;
  padding: 20px;
  border: 2px solid var(--border);
  border-radius: 5px;

  &.dtu-temporary {
    border-style: dashed;
  }

  .dtu-form-section-header {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-bottom: 15px;

    h3 {
      margin: 0;
    }
  }

  hr {
    margin: 30px 0;
  }
}

.dtu-form-prompt {
  p:last-of-type {
    margin: 0;
  }
}

.dtu-form-group-wrapper {
  display: flex;
  align-items: center;

  .dtu-form-group {
    flex: 1;
    margin-right: 20px;
  }
}

.dtu-form-group {
  margin-bottom: 25px;

  .mat-form-field-wrapper {
    width: 100%;
  }
}

.dtu-form-label {
  height: 18px;
  margin-bottom: 5px;
  color: var(--textLight);
  line-height: 18px;
  font-size: 14px;
  font-weight: 300;

  .dtu-required {
    opacity: 0.6;
  }
}

.dtu-form-field {
  width: 100%;
  line-height: 1.5;

  .error,
  .StripeElement--invalid {
    color: var(--red);
    border: 2px solid var(--red);
    border-radius: 5px;
  }

  ::placeholder {
    color: var(--textLight);
  }

  .mat-form-field-wrapper {
    padding: 0;
  }

  .mat-form-field-flex {
    align-items: center;
    padding: 0 !important;
    border-radius: 5px;
    background-color: var(--secondaryBackground);
    transition: background-color 0.2s ease-in-out;
  }

  &.mat-focused .mat-form-field-flex,
  .mat-form-field-flex:hover {
    background-color: var(--secondaryBackgroundHover);
  }

  &.mat-form-field-disabled {
    .mat-form-field-flex:hover {
      background-color: var(--secondaryBackground);
    }

    .mat-input-element {
      color: var(--textLight);
    }
  }

  .mat-form-field-infix {
    display: flex;
    padding: 0;
    border: none;
  }

  .mat-input-element {
    flex: 1;
    margin: 0;
    padding: 13px 15px;
    caret-color: var(--text);

    &:not(textarea) {
      max-height: 50px;
    }
  }

  #stripe-card {
    &.mat-input-element {
      border-radius: 5px;
      background-color: var(--secondaryBackground);

      &:hover {
        background-color: var(--secondaryBackgroundHover);
      }
    }
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-prefix {
    display: flex;
    padding: 13px 0 13px 15px;
    color: var(--textLight);
  }

  .mat-form-field-suffix {
    padding: 5px 10px 5px 0;
    color: var(--textLight);

    span {
      display: flex;
      align-items: center;

      .mat-icon {
        margin-left: 8px;
      }
    }
  }

  .mat-select {
    height: 50px;
    padding: 13px 15px;

    .mat-select-placeholder {
      color: var(--textLight);
    }

    &.mat-select-disabled {
      opacity: 0.4;
    }
  }

  .mat-select-value {
    color: var(--text) !important;
  }

  .mat-select-arrow {
    color: var(--textLight) !important;
  }
}

.mat-optgroup-label {
  color: var(--textLight);
}

.dtu-form-file-field {
  display: flex;
  align-items: center;
  padding: 0 15px;
  border-radius: 5px;
  background-color: var(--secondaryBackground);
  color: var(--text);
  line-height: 50px;

  .dtu-form-file-name {
    flex-grow: 2;
  }
}

.dtu-form-field-hint {
  display: flex;
  align-items: center;
  margin: 5px 5px 0 5px;
  color: var(--textLight);
  font-size: 0.75rem;

  &.dtu-error {
    color: var(--red);
  }

  .mat-icon {
    font-size: 16px;
    height: 16px;
    width: 16px;
    margin-right: 5px;
  }
}

.mat-select-panel {
  background-color: var(--secondaryBackground);

  &::-webkit-scrollbar {
    background-color: var(--secondaryBackground);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--secondaryBackgroundHover);
  }

  .mat-option {
    color: var(--text);

    &.mat-option-disabled {
      color: var(--textLight);
    }
  }
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--blue);
}

.mat-radio-group {
  display: block;
}

.mat-radio-button.mat-accent {
  display: block;

  .mat-radio-outer-circle {
    border-color: var(--tertiaryBackground);
  }

  .mat-radio-inner-circle {
    background-color: var(--blue);
  }

  &.mat-radio-checked .mat-radio-outer-circle {
    border-color: var(--blue);
  }

  &.mat-radio-disabled .mat-radio-label-content {
    color: var(--textLight);
  }

  .dtu-eliminated & .mat-radio-label-content {
    text-decoration: line-through;
  }

  .mat-radio-label {
    align-items: flex-start;
    white-space: normal;
  }

  .mat-radio-label-content {
    padding-left: 12px;
  }

  .mat-ripple {
    display: none;
  }

  p:last-of-type {
    margin: 0;
  }
}

.mat-checkbox {
  display: inline-block;

  .mat-checkbox-layout {
    align-items: flex-start;
    white-space: unset;
  }

  .mat-checkbox-inner-container {
    margin-top: 4px;
    margin-right: 12px;
  }

  .mat-checkbox-frame,
  &.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
    border-color: var(--tertiaryBackground) !important;
  }

  &.mat-checkbox-checked .mat-checkbox-background {
    background-color: var(--blue) !important;
  }

  &.mat-checkbox-disabled .mat-checkbox-frame {
    opacity: var(--opacityDisabled);
  }

  &.mat-checkbox-disabled .mat-checkbox-label {
    color: var(--textLight);
  }

  .dtu-eliminated & .mat-checkbox-label {
    text-decoration: line-through;
  }

  .mat-ripple {
    display: none;
  }

  p:last-of-type {
    margin: 0;
  }
}

.mat-pseudo-checkbox {
  color: var(--tertiaryBackground) !important;
}

.mat-primary .mat-pseudo-checkbox-checked {
  background: var(--blue) !important;
}

.dtu-form-group .ql-toolbar.ql-snow,
.dtu-form-group .ql-container.ql-snow {
  border: none;
  background-color: var(--secondaryBackground);
  color: var(--text);
  font-family: BerninaSans, 'Open Sans', sans-serif;
}

.dtu-form-group .ql-toolbar.ql-snow {
  border-radius: 5px 5px 0 0;

  button:hover,
  button.ql-active {
    color: var(--text);

    .ql-stroke {
      stroke: var(--text);
    }

    .ql-fill {
      fill: var(--text);
    }
  }

  .ql-stroke {
    stroke: var(--textLight);
  }

  .ql-fill,
  .ql-stroke.ql-fill {
    fill: var(--textLight);
  }
}

quill-editor {
  width: 100%;
}

.dtu-form-group .ql-container.ql-snow {
  border-radius: 0 0 5px 5px;

  .ql-editor {
    min-height: 150px;
    a {
      color: inherit;

      &:focus,
      &:hover {
        color: var(--textLight);
      }
    }

    p {
      max-width: unset;
      margin-bottom: 16px;
      font-size: 1rem;

      &:last-child {
        margin: 0;
      }
    }

    ul {
      max-width: unset;
      padding-left: 22px;
    }

    li {
      padding: 0;
    }
  }
}

.dtu-form-field-copy {
  .mat-input-element {
    padding-right: 60px;
  }

  .mat-icon-button {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}

.dtu-required-star-after {
  p:last-of-type {
    &::after {
      color: var(--red);
      content: ' * ';
    }
  }
}

.dtu-required-star-before {
  p:first-of-type {
    &::before {
      color: var(--red);
      content: ' * ';
    }
  }
}

.mat-option-text {
  display: flex !important;
  align-items: center;
}

.dtu-form-thumbnail {
  img {
    max-height: 121px;
    width: auto;
  }
}

.dtu-drag-drop-target {
  display: flex;
  height: 238px;
  width: 100%;
  max-width: 600px;
  padding: 20px;
  border-radius: 5px;
  background-color: var(--background);
  border: 3px dashed var(--secondaryBackgroundOverlayMed);
  transition: border 0.2s ease-in-out;
  align-items: center;
  line-height: 1;
  color: var(--text);

  .dtu-drag-drop-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex-grow: 2;

    .mat-icon {
      height: 60px;
      width: 60px;
      margin-bottom: 15px;
      font-size: 60px;
      color: var(--textLight);
    }

    .mat-progress-bar {
      width: 120px;
    }

    .dtu-drag-drop-action {
      margin-bottom: 15px;
    }
  }

  &.dtu-file-hover {
    border: 3px dashed var(--blue);

    .dtu-drag-drop-action {
      pointer-events: none;
    }
  }
}

.dtu-form-editable {
  display: inline-block;
  min-width: 300px;
  padding: 2px 6px;
  border-radius: 5px;
  background-color: var(--secondaryBackground);

  &:empty {
    height: unset;
  }

  &:focus,
  &:hover {
    background-color: var(--secondaryBackgroundHover);
  }
}

.dtu-image-header {
  .dtu-form-editable {
    background-color: rgba(white, 0.08);

    &:focus,
    &:hover {
      background-color: rgba(white, 0.1);
    }
  }
}

.dtu-option {
  line-height: 1em !important;
}

.dtu-option .mat-option-text {
  display: inline-block !important;
}

.dtu-new-styles {
  .dtu-form-section {
    border-radius: var(--borderRadius);
  }

  .dtu-form-field {
    .error,
    .StripeElement--invalid {
      border-radius: var(--borderRadius);
    }

    .mat-form-field-flex {
      border-radius: var(--borderRadius);
      background-color: var(--background);
      border: 2px solid var(--border);
      transition: border 0.2s ease-in-out;
    }

    &.mat-focused .mat-form-field-flex,
    .mat-form-field-flex:hover {
      border: 2px solid var(--blue);
      background-color: unset; //remove when new styles are permanent
    }

    &.mat-form-field-disabled {
      .mat-form-field-flex {
        .dtu-light-theme & {
          border: 2px solid var(--palette-neutral-50);
        }

        .dtu-dark-theme & {
          border: 2px solid var(--palette-neutral-900);
        }
      }
    }

    #stripe-card {
      &.mat-input-element {
        border-radius: 8px;
        background-color: var(--formBackground);
        border: 2px solid var(--border);

        &:hover {
          border: 2px solid var(--blue);
        }
      }
    }

    .mat-datepicker-toggle {
      padding: 5px 10px 5px 0;
      color: var(--textLight);
    }
  }

  .dtu-form-file-field {
    border-radius: 8px;
    border: 2px solid var(--border);
    transition: border ease-in-out 0.2s;
    overflow: hidden;

    &:hover,
    &:focus {
      border-color: var(--blue);
    }
  }

  .mat-select-panel.mat-primary {
    &::-webkit-scrollbar {
      background-color: var(--tertiaryBackground);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--thumbBackground);
    }

    .mat-option {
      &.mat-selected:not(.mat-option-multiple),
      &:hover:not(.mat-option-disabled),
      &:focus:not(.mat-option-disabled) {
        background-color: var(--secondaryBackgroundHover);
      }

      &.mat-option-disabled {
        color: var(--textLight);
      }
    }
  }

  .mat-select-value {
    color: var(--text) !important;
  }

  .mat-select-arrow {
    color: var(--textLight) !important;
  }

  quill-editor {
    width: 100%;
    border-radius: 8px;
    border: 2px solid var(--border);
    overflow: hidden;
    transition: border 0.2s ease-in-out;

    &:hover {
      border: 2px solid var(--blue);
    }
  }

  .ql-container.ql-bubble {
    border-radius: 8px;
    background: var(--secondaryBackground);
    font-family: BerninaSans, 'Open Sans', sans-serif;
  }

  .dtu-form-group .ql-toolbar.ql-snow,
  .dtu-form-group .ql-container.ql-snow {
    background-color: var(--formBackground);
  }

  .dtu-form-group .ql-toolbar.ql-snow {
    border: unset;
    border-bottom: 2px solid var(--border);
  }

  .dtu-form-group .ql-container.ql-snow {
    border: unset;
  }

  .dtu-drag-drop-target {
    border-radius: var(--borderRadius);
  }

  .dtu-form-editable {
    border-radius: var(--borderRadius);
  }

  .dtu-image-header {
    .dtu-form-editable {
      transition: all ease-in-out 0.2s;

      &:focus,
      &:hover {
        background-color: rgba(white, 0.25);
      }
    }
  }
}
