.mat-progress-bar {
  border-radius: 5px;
}

.mat-progress-bar-background {
  display: none;
}

.mat-progress-bar-fill::after {
  background-color: var(--blue);
}

.mat-progress-bar-buffer {
  background-color: var(--secondaryBackground);
}

.dtu-new-styles {
  .mat-progress-bar {
    border-radius: var(--borderRadius);
  }
}
