.ql-bubble.ql-container {
  .ql-toolbar,
  .ql-picker,
  .ql-picker.ql-expanded .ql-picker-label {
    color: var(--text);
  }

  .ql-toolbar {
    background: var(--secondaryBackgroundHover);
    border-radius: 25px;

    .ql-picker-label:hover {
      color: var(--textLight);

      .ql-stroke {
        stroke: var(--textLight);
      }
    }

    button:hover .ql-stroke {
      stroke: var(--textLight);
    }
  }

  .ql-stroke {
    stroke: var(--text);
  }

  .ql-picker.ql-expanded .ql-picker-options {
    background: var(--tertiaryBackground);
  }
}

.dtu-event-activity table code {
  overflow-wrap: anywhere;
}

.dtu-event-activity p:empty {
  display: none;
}

.dtu-lab-guide-exercise {
  p:empty {
    display: none;
  }

  ul li ul {
    margin-top: 16px;
  }
}

.dtu-markdown-editor-container {
  .dtu-markdown-editor-tab-list {
    .mat-tab-header {
      margin: 0;

      .mat-ink-bar {
        height: 2px;
      }

      &::after {
        height: 2px;
        border-bottom: 2px solid var(--border);
      }
    }

    .mat-tab-labels .mat-tab-label {
      height: 48px;
    }
  }

  .dtu-form-field .mat-form-field-flex {
    border-radius: 0 0 4px 4px;
  }
}
