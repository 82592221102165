.dtu-filter-bar {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 40px;
}

.dtu-search-bar {
  width: 100%;
  max-width: 500px;

  &.dtu-form-group {
    margin: 0;
  }

  &:empty {
    height: 50px;
    margin-bottom: 40px;
    border-radius: 5px;
    background-color: var(--secondaryBackground);
  }

  .mat-icon-button {
    &:focus:not([disabled]),
    &:hover:not([disabled]) {
      background: transparent;
    }
  }

  &.dtu-admin-filter {
    max-width: unset;

    .mat-chip-list-wrapper {
      display: flex;

      .mat-chip {
        margin-left: 15px;

        &:first-of-type {
          margin-left: 20px;
        }
      }

      input {
        flex: 1;
      }
    }
  }
}

.dtu-filter-options {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.dtu-admin-filter {
  flex: 1;
  margin-right: 40px;

  .mat-chip-list {
    width: 100%;
  }
}

.dtu-new-styles {
  .dtu-search-bar:empty {
    border-radius: var(--borderRadius);
  }
}
