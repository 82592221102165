$dropshadows: (
  light: (
    10: 0px 4px 10px 0px rgba(2, 9, 27, 0.15),
    20: 0px 8px 20px 0px rgba(2, 9, 27, 0.15),
  ),
  dark: (
    10: 0px 4px 10px 0px var(--black),
    20: 0px 8px 20px 0px var(--black),
  ),
);

:root {
  @each $theme, $level in $dropshadows {
    @each $key, $value in $level {
      $keyname: '--dropshadow-' + $theme + '-' + $key;
      #{$keyname}: #{$value};
    }
  }

  --opacityDisabled: 0.4;
  --borderRadius: 8px;
}
