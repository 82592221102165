.dtu-section {
  position: relative;
  margin-bottom: 3.75rem;
}

.dtu-section-header {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  justify-content: space-between;
  min-height: 40px;
  margin-bottom: 15px;

  h2,
  h3 {
    margin: 0;
  }
}

.dtu-section-tabs {
  display: flex;
  flex-grow: 1;
  margin-left: 20px;
}

.dtu-section-tab {
  position: relative;
  margin: 0 5px;
  padding: 8px 5px;
  opacity: 1;
  color: var(--text);
  font-size: 1rem;
  transition: all 0.2s ease-in-out;
  border: none;
  background: none;
  cursor: pointer;

  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 3px;
    border-radius: 2px;
    content: '';
  }

  &.dtu-active::after {
    background: linear-gradient(to right, var(--blue) 0%, var(--purple) 100%);
  }

  &:focus,
  &:hover {
    color: var(--text);
  }
}

.dtu-section-filters {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 40px;
}

.dtu-section-search {
  width: 100%;
  max-width: 500px;

  .mat-icon-button {
    &:focus,
    &:hover {
      background: transparent;
    }
  }
}

.dtu-section-options {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.dtu-new-styles {
  .dtu-section-tab.dtu-active::after {
    background: var(--horizontalLinearGradient);
  }
}
