.mat-accordion {
  .mat-expansion-panel {
    max-width: 700px;
    margin-bottom: 30px;
    box-shadow: none !important;
    background-color: transparent;
    color: var(--text);
  }

  .mat-expansion-panel-header,
  .mat-expansion-panel-header.mat-expanded {
    height: unset;
    padding: 0 5px 0 0;
    font-size: 1rem;

    h2,
    h3 {
      color: var(--text);
      margin: 0;
      line-height: 24px;
    }
  }

  .mat-expansion-panel-body {
    padding: 10px 0;
  }

  .mat-expansion-panel-content {
    input,
    textarea,
    .mat-form-field-infix {
      font-size: 1rem;
    }
  }

  &.dtu-form-accordion .mat-expansion-panel {
    max-width: unset;
  }
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled='true']),
.mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled='true']),
.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled='true']) {
  background: transparent !important;
}

.mat-expansion-indicator {
  margin-left: 20px;
}

.mat-expansion-panel-header .mat-expansion-indicator::after {
  color: var(--textLight);
}

.dtu-step-expansion-panel {
  .mat-expansion-panel-header .mat-content {
    flex-direction: column;
  }
}
