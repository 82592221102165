.mat-autocomplete-panel {
  .mat-option {
    background-color: var(--secondaryBackground);
    color: var(--text);

    .mat-option-text small {
      margin-left: 5px;
    }

    &:focus,
    &:hover,
    &.mat-active {
      background-color: var(--secondaryBackgroundHover);
    }
  }
}

.mat-autocomplete-panel .mat-option.mat-selected {
  &:not(.mat-option-disabled) {
    background: var(--secondaryBackgroundHover);
    color: var(--text);

    &:not(.mat-active) {
      &:not(:hover) {
        background: var(--secondaryBackground);
        color: var(--text);
      }
      &:hover {
        color: var(--text);
      }
    }
  }
}
