.dtu-tab-container {
  position: relative;

  .dtu-tab-loading {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 101;
  }
}

.mat-tab-header {
  position: relative;
  margin-bottom: 40px;
  border: none;

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    border-bottom: 4px solid var(--border);
    border-radius: 2px;
    content: '';
  }

  .mat-ink-bar {
    height: 4px;
    border-radius: 2px;
    background: linear-gradient(to right, var(--blue) 0%, var(--purple) 100%);
  }

  .mat-tab-header-pagination-chevron {
    border-color: var(--textLight);
  }
}

.mat-tab-labels {
  display: flex;

  .mat-tab-label {
    min-width: auto;
    height: 70px;
    margin-right: 10px;
    padding: 0 15px;
    opacity: 1;
    color: var(--text);
    font-size: 1rem;
    transition: all 0.2s ease-in-out;

    &:empty {
      width: 100px;
      height: 20px;
      margin: 25px 10px 25px 0;
      border-radius: 5px;
      background-color: var(--secondaryBackground);
      background-image: linear-gradient(to bottom right, transparent 0, var(--loadingAnimation) 50%, transparent 100%);
      background-position: -1000px 0;
      background-repeat: no-repeat;
      background-size: 1000px 100%;
      animation: shimmer 4s infinite;
      pointer-events: none;
      cursor: auto;

      @keyframes shimmer {
        0% {
          background-position: -1000px 0;
        }
        100% {
          background-position: 1000px 0;
        }
      }
    }

    &.mat-tab-disabled {
      margin: 0 0 0 auto;
      padding: 0;
    }

    .mat-button {
      margin: 0;
    }
  }
}

.mat-tab-body-content {
  overflow: hidden !important;
}

.mat-tab-labels .mat-tab-label.mat-tab-disabled:not(:last-of-type) {
  opacity: 0.4;
  padding: 0 15px;
  margin-right: 10px;
  margin-left: 0;

  &:hover {
    color: var(--textLight);
  }
}

.dtu-content-explorer-sort-options {
  display: flex;
  align-items: center;

  .mat-tab-header {
    margin: 0;
  }

  .mat-tab-label {
    height: 48px;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.dtu-new-styles {
  .mat-ink-bar {
    background: var(--horizontalLinearGradient);
  }

  .dtu-light-theme & .mat-tab-header:after {
    border-bottom: 4px solid var(--palette-neutral-50);
  }

  .dtu-dark-theme & .mat-tab-header:after {
    border-bottom: 4px solid var(--palette-neutral-900);
  }

  .mat-tab-group.mat-primary .mat-tab-labels {
    .mat-tab-label {
      &.cdk-program-focused,
      &:focus,
      &:hover,
      &.mat-tab-label-active {
        background-color: none !important;
      }
    }

    .mat-tab-label.mat-tab-disabled.cdk-focused {
      background-color: transparent;
    }
  }

  .mat-tab-body-content {
    overflow: hidden !important;
  }

  .mat-tab-labels .mat-tab-label.mat-tab-disabled:not(:last-of-type) {
    padding: 0 15px;
    margin-right: 10px;
    margin-left: 0;

    &:hover {
      color: var(--text);
    }
  }
}
