.dtu-aside-container {
  margin-bottom: 20px;
  padding: 20px;
  background: var(--secondaryBackground);
  border-radius: 8px;

  aside {
    max-height: 336px;
    overflow: auto;
    margin-left: -20px;
    margin-right: -20px;
    padding-top: 0;
    padding-right: 20px;
    padding-bottom: 0;
    padding-left: 20px;

    &::-webkit-scrollbar {
      background-color: var(--secondaryBackground);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--secondaryBackgroundHover);
    }

    p {
      color: var(--text);
    }
  }
}

.dtu-new-styles {
  .dtu-aside-container {
    border-radius: var(--border-radius);
  }
}
