.mat-tooltip {
  background-color: var(--tooltipBackground);
  font-size: 0.75rem;
}

.dtu-tooltip-large {
  font-size: 0.875rem;
}

.dtu-tooltip-cost-breakdown {
  white-space: nowrap;
  max-width: unset !important;
}

.dtu-tooltip-multiline {
  white-space: pre-line;
  text-align: center;
}
