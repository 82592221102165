.dtu-menu.mat-menu-panel {
  min-width: 140px;
  max-height: 240px;
  min-height: unset;
  border-radius: 5px;
  background-color: var(--secondaryBackground);

  &::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: var(--secondaryBackgroundHover);
  }

  .mat-menu-content {
    padding: 0;
  }

  .mat-menu-item {
    color: var(--text);

    &[disabled] {
      opacity: var(--opacityDisabled);
      color: var(--textLight);
    }

    &:focus:not([disabled]),
    &:hover:not([disabled]) {
      text-decoration: none;
      background-color: var(--secondaryBackgroundHover);
    }

    &.dtu-active {
      color: var(--blue);
    }
  }

  &.dtu-menu-wide {
    width: 180px;
  }
}

.dtu-menu.dtu-notification-menu.mat-menu-panel {
  max-width: 320px;

  .mat-menu-item {
    display: flex;
    justify-content: space-between;
    height: 100%;
    padding: 15px;
    white-space: normal;
    line-height: unset;
  }

  .dtu-notification-title,
  .dtu-notification-message {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .dtu-notification-title {
    margin-bottom: 4px;
    font-size: 1rem;
    font-weight: bold;
  }

  .dtu-notification-message {
    font-weight: 300;
  }

  .dtu-notification-details {
    margin-top: 6px;
    color: var(--textLight);
  }

  .dtu-notification-actions {
    .mat-icon-button {
      margin: -2px -2px 0 0;
    }

    .mat-icon {
      margin: 0;
      color: var(--textLight);
    }
  }

  .dtu-notification-empty {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 30px 45px;
    opacity: 1;
    color: var(--textLight);

    .mat-icon {
      height: 60px;
      width: 60px;
      margin-right: 0px;
      margin-bottom: 20px;
      font-size: 60px;
      color: var(--textLight);
    }
  }
}

.mat-menu-item-submenu-trigger::after {
  color: var(--text);
}

.dtu-new-styles {
  .dtu-menu.mat-menu-panel {
    border-radius: var(--borderRadius);
  }
}
