.dtu-ceus-accumulated {
  .mat-tab-header {
    margin-bottom: 10px;
  }

  .mat-tab-labels .mat-tab-label {
    height: 50px;

    &:last-of-type {
      margin-right: 0;
    }
  }
}
