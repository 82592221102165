.dtu-header {
  padding: 40px 0;

  &.dtu-tabbed-header {
    padding-bottom: 10px;
  }

  &.dtu-image-header {
    display: flex;
    align-items: flex-end;
    padding-bottom: 20px;
    background-color: var(--secondaryBackground);
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.3) 100%),
      url('/images/ondemand/dt_header_bkgd_course_default.png');
    background-repeat: no-repeat;
    background-size: cover;

    @media only screen and (min-width: $dtu-bp-sm) {
      height: 180px;
    }

    .dtu-wrapper {
      align-items: flex-end;
    }

    .dtu-header-title {
      h1 {
        color: white;
      }

      h2 {
        color: rgba(white, 0.5);
      }

      h1:empty,
      h2:empty {
        background-color: rgba(white, 0.1);
      }
    }

    .dtu-header-actions {
      margin-top: 20px;
    }
  }

  .dtu-cover-header-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &.ng-lazyloaded {
      opacity: 1;
    }

    &:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.3) 100%);
      content: '';
    }
  }

  .dtu-wrapper {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  .dtu-header-title {
    min-height: 40px;

    h1 {
      margin: 0;

      &:empty {
        width: 300px;
      }
    }

    h2 {
      margin-bottom: 0;
      color: var(--textLight);
      font-size: 1rem;
      text-transform: uppercase;

      &:empty {
        width: 100px;
        height: 20px;
        margin-bottom: 6px;
      }
    }

    p {
      margin: 15px 0 0 0;
    }

    .dtu-header-metadata {
      display: inline-flex;
      align-items: center;
      margin-top: 6px;
      font-size: 0.875rem;
      color: var(--textLight);

      &.mat-button {
        min-height: 20px;
        padding: 6px 10px;
        background-color: rgba(white, 0.08);
        line-height: 1;

        .mat-button-wrapper {
          display: flex;
          align-items: center;
        }
      }

      .mat-icon {
        width: 15px;
        height: 15px;
        margin-right: 6px;
        font-size: 15px;
      }

      .dtu-header-metadata-count {
        margin-left: 2px;
        font-size: 0.75rem;
      }
    }
  }

  .dtu-header-actions {
    display: flex;
    align-items: center;

    .mat-button {
      flex-shrink: 0;
    }
  }
}

.dtu-new-styles {
  .dtu-header.dtu-image-header {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.3) 100%),
      url('/images/ondemand/dtu_header_bkgd.svg');
  }

  .dtu-header .dtu-header-title .dtu-header-metadata {
    text-decoration: none;

    &.mat-button:hover {
      background-color: rgba(white, 0.25);
    }
  }
}
