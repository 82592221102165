.dtu-video-player:empty {
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  border-radius: 5px;
  background-color: var(--secondaryBackground);
  background-image: linear-gradient(90deg, transparent 0, var(--loadingAnimation) 50%, transparent 100%);
  background-position: -100% 0;
  background-size: 50% 100%;
  background-repeat: no-repeat;
  animation: loading 5s infinite;
}

@keyframes loading {
  to {
    background-position: 200% 0, 0 0;
  }
}

.dtu-new-styles .dtu-video-player:empty {
  border-radius: var(--borderRadius);
}
