.cdk-overlay-container {
  z-index: 10000;
}

.dtu-dialog {
  .mat-dialog-container {
    padding: 20px;
    border-radius: 5px;
    background-color: var(--background);
    color: var(--text);
  }

  .mat-dialog-title {
    margin-bottom: 20px;
  }

  .mat-dialog-content,
  .mat-dialog-actions {
    margin: 0 -20px;
    padding: 0 20px;
  }

  .mat-dialog-content.dtu-dialog-loading {
    position: relative;
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
  }

  .mat-dialog-actions {
    min-height: 40px;
    margin-top: 20px;
  }
}

.dtu-test-image-dialog {
  display: flex;
  justify-content: center;
  width: unset !important;
  margin: 40px;

  .mat-dialog-container {
    width: unset;
    padding: 0;
    border-radius: 0;
  }
}

.dtu-new-styles {
  .dtu-dialog .mat-dialog-container {
    border-radius: var(--borderRadius);
  }
}
