.dtu-volume-controls .mat-slider {
  min-width: 80px;

  .mat-slider-wrapper {
    top: 10px;
  }

  .mat-slider-track-wrapper {
    height: 3px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.25);
  }

  .mat-slider-track-background,
  .mat-slider-track-fill {
    height: 100%;
  }

  .mat-slider-track-background {
    background-color: unset;
  }

  .mat-slider-track-fill,
  .mat-slider-thumb {
    background-color: var(--white) !important;
  }

  .mat-slider-thumb {
    height: 8px;
    width: 8px;
    border: unset;
    bottom: -4px;
    right: -4px;
    transform: unset;
  }

  .mat-slider-thumb-label {
    background-color: var(--white);
  }
}
