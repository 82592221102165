html {
  font-family: BerninaSans, 'Open Sans', sans-serif;
  font-size: 16px;
}

h1,
h2,
h3,
h4 {
  margin-top: 0;
  margin-bottom: 15px;
  line-height: 1.2;
  font-weight: bold;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.25rem;
}

h3 {
  font-size: 1.1rem;
}

h4 {
  font-size: 1rem;
}

p {
  max-width: 650px;
  margin-top: 0;
  margin-bottom: 16px;
  line-height: 1.5;
  font-size: 1rem;
  font-weight: 400;
}

a {
  color: inherit;
  text-decoration: underline;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--textLight);
  }

  &.hide-underline {
    text-decoration: none;
  }
}

ul:not(.dt-menu-list) {
  max-width: 650px;
  margin: 0 0 16px 0;
  padding: 0;

  li:not(.dt-menu-item) {
    position: relative;
    display: block;
    margin-bottom: 16px;
    padding-left: 22px;
    line-height: 22px;
    font-size: 1rem;
    font-weight: 400;

    &::before {
      position: absolute;
      top: 5px;
      left: 0;
      width: 12px;
      height: 12px;
      border: 2px solid var(--tertiaryBackground);
      border-radius: 50%;
      content: '';
    }
  }

  &.dtu-icon-list {
    list-style: none;

    li:not(.dt-menu-item) {
      display: flex;
      align-items: center;
      padding-left: 0;

      &::before {
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border: none;
        border-radius: initial;
        content: '';
      }

      mat-icon {
        margin-right: 15px;
        color: var(--textLight);
      }
    }
  }
}

ol {
  li {
    margin-bottom: 16px;
    line-height: 22px;
    font-size: 1rem;
    font-weight: 400;
  }
}

input,
textarea,
button {
  font-family: inherit;
}

blockquote {
  margin: 0 0 16px 0;
  padding: 0 16px;
  color: var(--textLight);
  border-left: 4px solid var(--tertiaryBackground);
}

code {
  padding: 0.2em 0.4em;
  border-radius: 5px;
  background-color: var(--codeBackgroundHover);
  color: white;
  font-family: VeraMono;
  font-size: 0.875rem;
}

pre {
  margin: 0 0 16px 0;
  border-radius: 5px;
  background-color: var(--codeBackground);
  font-family: VeraMono;
  font-size: 0.875rem;

  &.dtu-code-block {
    padding: 16px;
  }

  .hljs {
    padding: 16px;
    background: none;

    &::-webkit-scrollbar {
      background-color: var(--codeBackground);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--codeBackgroundHover);
    }
  }

  code {
    padding: 0;
    background: unset;
  }
}

.text-muted {
  opacity: 0.6;
}

h1,
h2,
h3,
h4,
p {
  &:empty {
    border-radius: 5px;
    background-color: var(--secondaryBackground);
    background-image: linear-gradient(to right, transparent 0, var(--loadingAnimation) 50%, transparent 100%);
    background-position: -1000px 0;
    background-repeat: no-repeat;
    background-size: 1000px 100%;
    animation: shimmer 4s infinite;

    @keyframes shimmer {
      0% {
        background-position: -1000px 0;
      }
      100% {
        background-position: 1000px 0;
      }
    }
  }
}

h1,
h2,
h3,
h4 {
  &:empty {
    width: 200px;
  }
}

h1:empty {
  height: 2.2rem;
}
h2:empty {
  height: 1.5rem;
}
h3:empty {
  height: 1.1rem;
}
h4:empty {
  height: 1rem;
}
p:empty {
  width: 40%;
  height: 1.4rem;

  &.dtu-full-width {
    width: 100%;
  }
}

.dtu-new-styles {
  code,
  pre {
    border-radius: var(--borderRadius);
  }

  .text-muted {
    opacity: var(--opacityDisabled);
  }

  h1,
  h2,
  h3,
  h4,
  p {
    &:empty {
      border-radius: var(--borderRadius);
    }
  }
}
