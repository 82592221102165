.dtu-recently-viewed-tile {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  border-radius: 5px;
  margin-top: -1px;
  padding: 20px 14px;
  border: 1px solid transparent;
  background: none;
  text-decoration: none;
  transition: all ease-in-out 0.2s;
  cursor: pointer;

  &:focus,
  &:hover {
    border-color: var(--border);
    background-color: var(--backgroundHoverLight);
    color: inherit;

    .dtu-recently-viewed-tile-actions {
      opacity: 1;
    }
  }

  &::after {
    position: absolute;
    right: 5px;
    bottom: -1px;
    left: 5px;
    height: 1px;
    background-color: var(--border);
    content: '';
  }

  &:empty {
    height: 94px;
    background-color: var(--secondaryBackground);
  }
}

.dtu-recently-viewed-tile-thumbnail {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 64px;
  height: 64px;
  border-radius: 5px;
  margin-right: 12px;
  background-color: var(--blue);
  color: white;
}

.dtu-recently-viewed-tile-name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-break: break-word;
  text-overflow: ellipsis;
}

.dtu-recently-viewed-tile-description {
  display: flex;
  align-items: center;
  margin-top: 4px;
  line-height: 16px;
  font-size: 12px;
  color: var(--textLight);

  .mat-icon {
    width: 16px;
    height: 16px;
    margin-right: 4px;
    margin-bottom: 2px;
    font-size: 16px;
  }
}

.dtu-recently-viewed-tile .dtu-recently-viewed-tile-actions {
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 0;
  border-radius: 5px 0 5px 0;
  transition: all ease-in-out 0.2s;

  &:focus,
  &:hover {
    opacity: 1;
  }
}

.dtu-new-styles {
  .dtu-recently-viewed-tile,
  .dtu-recently-viewed-tile-thumbnail {
    border-radius: var(--borderRadius);
  }

  .dtu-recently-viewed-tile .dtu-recently-viewed-tile-actions {
    border-radius: var(--borderRadius) 0 var(--borderRadius) 0;
  }
}
