.dtu-announcement {
  display: flex;

  .dtu-user {
    align-items: flex-start;

    .dtu-user-profile {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 60px;
      width: 60px;
      height: 60px;
      margin-right: 20px;

      .dtu-user-initials {
        font-size: 1.25rem;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.dtu-announcement-title {
  margin-bottom: 4px;
  font-weight: bold;
}

.dtu-announcement-subtitle {
  margin-bottom: 15px;
  color: var(--textLight);
}

.dtu-announcements-empty {
  flex-direction: column;
  align-items: center;
  color: var(--textLight);
  margin-top: 20px;

  .dtu-announcement-title {
    width: 220px;
    margin: 0;
    text-align: center;
  }

  .mat-icon {
    height: 60px;
    width: 60px;
    font-size: 60px;
    margin-bottom: 20px;
  }
}
