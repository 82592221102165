.dtu-resource-list {
  max-width: 650px;

  dtu-resource:last-of-type .dtu-resource {
    border: none;
  }
}

.dtu-resource {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 20px;

  .dtu-resource-details {
    margin-left: 10px;

    > :first-child {
      transition: color 0.2s ease-out;

      &:hover {
        color: var(--textLight);
        cursor: pointer;
      }
    }
  }
}
