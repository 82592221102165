.dtu-inline-selector {
  div.dtu-form-field {
    display: flex;
    padding: 6px;
    background-color: var(--secondaryBackground);
    border-radius: 5px;

    .dtu-tag {
      margin: 0 5px 0 0;
    }
  }

  .dtu-form-field input {
    min-width: 150px;
  }

  .dtu-tag-group {
    width: 100%;
    margin: 0;
    min-height: 50px;
  }

  .dtu-tag {
    height: 38px;
    width: fit-content;
    background: var(--tertiaryBackground);

    .mat-icon-button {
      max-height: 14px;
      max-width: 14px;
      margin-left: 12px;

      .mat-icon {
        width: 14px;
        height: 14px;
        line-height: 1;
        font-size: 14px;
      }

      &:hover {
        color: var(--text);
        background: var(--tertiaryBackground);
      }
    }
  }
}

.dtu-new-styles {
  .dtu-inline-selector div.dtu-form-field {
    border-radius: var(--borderRadius);
  }
}
