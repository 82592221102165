.dtu-table {
  .dtu-asset-preview {
    display: flex;
    align-items: center;

    .dtu-asset-thumbnail {
      display: flex;
      justify-content: center;
      min-width: 90px;
      width: 90px;
      height: 50px;
      margin-right: 24px;

      img {
        max-width: 100%;
        max-height: 100%;
        height: auto;
        border-radius: 5px;
      }
    }

    .dtu-asset-description {
      .dtu-asset-description-label {
        color: var(--textLight);
        text-transform: uppercase;
        font-size: 0.6875rem;
      }
    }
  }

  .dtu-asset-progress {
    display: flex;
    align-items: center;
  }

  .dtu-asset-progress-label {
    width: 35px;
    margin-left: 8px;
  }

  .dtu-asset-progress-bar {
    flex: 1;
    max-width: 80px;
    height: 6px;
    border-radius: 5px;
    background-color: var(--secondaryBackground);

    .dtu-asset-progress-current {
      min-width: 6px;
      height: 6px;
      border-radius: 8px;
      background: var(--blue);

      &.dtu-asset-progress-complete {
        background: var(--green);
      }

      &.dtu-asset-progress-expired {
        background: var(--yellow);
      }

      &.dtu-asset-progress-failed {
        background: var(--red);
      }
    }
  }
}

.dtu-new-styles {
  .dtu-table {
    .dtu-asset-progress-bar,
    .dtu-asset-progress-bar .dtu-asset-progress-current,
    .dtu-asset-preview .dtu-asset-thumbnail img {
      border-radius: var(--borderRadius);
    }
  }
}
