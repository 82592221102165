.dtu-tile-group {
  display: flex;
  flex-flow: row wrap;
  --dtu-tiles-per-row: 1;

  @media only screen and (min-width: $dtu-bp-sm) {
    margin: -10px;
    --dtu-tiles-per-row: 2;
  }

  @media only screen and (min-width: $dtu-bp-md) {
    --dtu-tiles-per-row: 3;
  }

  @media only screen and (min-width: $dtu-bp-lg) {
    --dtu-tiles-per-row: 4;
  }
}

.dtu-tile-flat-group {
  margin: 0;
  .dtu-tile {
    margin: 0 0 30px 0;
  }
}

.dtu-tile {
  width: 100%;
  max-width: 430px;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 8px 0 var(--boxShadowColor);
  background-color: var(--tileBackground);
  color: var(--text);
  text-decoration: none;
  transition: all 0.2s ease-in-out;

  &.dtu-tile-flat {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: none;
    margin-bottom: 40px;
    box-shadow: none;
    background-color: transparent;
    cursor: pointer;

    &.dtu-active,
    &:hover {
      .dtu-tile-thumbnail {
        .dtu-tile-thumbnail-icon {
          opacity: 0.8;
          background: var(--thumbnailBackground);
        }
      }
    }

    &.dtu-active {
      .dtu-tile-progress {
        display: none;
      }
    }

    .dtu-tile-thumbnail {
      width: 120px;
      min-width: 120px;
      height: 67.5px;
      padding: 0;
      border-radius: 5px;
      margin-right: 20px;

      .dtu-tile-thumbnail-icon {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        border-radius: 5px;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;

        mat-icon {
          font-size: 40px;
          height: auto;
          color: var(--white);
        }
      }

      .dtu-tile-progress {
        right: 6px;
        bottom: 6px;
        left: 6px;
        height: 5px;

        .dtu-tile-progress-current {
          min-width: 5px;
          height: 5px;
          background-color: var(--blue);
        }

        .dtu-tile-progress-complete {
          background: var(--green);
          width: 100%;
        }
      }

      .dtu-tile-duration {
        position: absolute;
        top: 0;
        right: 0;
        margin: 6px;
        padding: 0 8px;
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.5);
        color: white;
        line-height: 18px;
        font-size: 0.75rem;
      }
    }

    .dtu-tile-content {
      padding: 0;

      .dtu-tile-title {
        display: flex;
        align-items: center;
        height: unset;
        margin-bottom: 5px;

        .dtu-tile-flag-group {
          position: relative;
          top: auto;
          left: auto;
          margin-left: 15px;
        }
      }

      .dtu-tile-description {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        max-width: 500px;
        overflow: hidden;
        line-height: 1.5;
        font-size: 1rem;
        font-weight: 300;
      }
    }
  }

  &.dtu-tile-leaflet {
    display: block;
    width: 100%;
    padding: 20px;
    margin: 0;
    border-radius: 5px;
    background: none;
    color: inherit;
    text-decoration: none;
    transition: all 0.45s ease;
    box-shadow: none;

    .dtu-tile-content {
      padding: 0;

      .dtu-tile-title {
        height: auto;
        margin-bottom: 4px;
      }
    }

    &:focus {
      background: none;
      box-shadow: none;
    }

    &:hover {
      background: var(--secondaryBackground);
      box-shadow: var(--tileShadowHover);
      text-decoration: none;
      color: inherit;
    }
  }

  &.dtu-tile-dashboard {
    display: flex;
    align-items: center;
    max-width: 200px;
    margin: 0 10px 10px 0;
    padding: 10px;
    box-shadow: none;
    background-color: transparent;

    &:focus,
    &:hover {
      box-shadow: none;
      background-color: var(--backgroundHover);
    }

    .dtu-tile-dashboard-icon {
      background: linear-gradient(to bottom right, var(--blue) 0%, var(--purple) 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .dtu-tile-dashboard-title {
      margin-left: 15px;
    }
  }

  .dtu-tile-thumbnail {
    position: relative;
    padding-top: 56.25%;
    border-radius: 5px 5px 0 0;
    background: var(--tileThumbnailLoading);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(black, 0) 0%, rgba(black, 0), rgba(black, 0.2) 100%);
      content: '';
    }

    &:empty {
      background: var(--secondaryBackgroundHover);

      &::after {
        background: none;
      }
    }

    .dtu-tile-thumbnail-image {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      padding: 20px;
      border-radius: 5px 5px 0 0;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      color: white;
      font-size: 0.875rem;
      font-weight: bold;
      text-align: center;

      &.ng-lazyloaded,
      &.dtu-img-loaded {
        opacity: 1;
      }
    }

    img.dtu-tile-thumbnail-image {
      height: 100%;
      width: 100%;
      padding: 0;
    }

    .dtu-thumbnail-text {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: var(--white);
    }

    .dtu-tile-progress {
      position: absolute;
      right: 15px;
      bottom: 15px;
      left: 15px;
      height: 6px;
      border-radius: 5px;
      background-color: rgba(white, 0.25);

      .dtu-tile-progress-current {
        min-width: 6px;
        height: 6px;
        border-radius: 5px;
        background: var(--blue);

        &.dtu-tile-progress-complete {
          background: var(--green);
        }
      }
    }
  }

  .dtu-tile-flag-group {
    position: absolute;
    top: 15px;
    left: 15px;
    display: flex;
  }

  .dtu-tile-flag {
    margin-right: 10px;
    padding: 0 8px;
    border-radius: 5px;
    line-height: 18px;
    font-size: 0.625rem;
    font-weight: bold;
    text-transform: uppercase;
    background-color: var(--secondaryBackground);
    color: var(--text);

    &.dtu-tile-flag-primary {
      background: linear-gradient(120deg, var(--blue) 0%, var(--purple) 100%);
      color: white;
    }
  }

  .dtu-tile-favorite {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    &.dtu-active {
      opacity: 1;

      .mat-icon {
        color: white;
      }
    }

    .mat-icon-button {
      &:focus,
      &:hover {
        background-color: rgba(white, 0.08);
      }
    }
  }

  &:focus .dtu-tile-favorite,
  &:hover .dtu-tile-favorite {
    opacity: 1;
  }

  .dtu-tile-content {
    padding: 20px;

    .dtu-tile-label {
      margin-bottom: 2px;
      font-size: 0.6875rem;
      font-weight: bold;
      text-transform: uppercase;
      color: var(--textLight);
    }

    .dtu-tile-title {
      height: 44px;
      margin-bottom: 25px;
      line-height: 22px;
      font-size: 1rem;
      font-weight: bold;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .dtu-tile-subtitle {
      margin-top: 4px;
      font-size: 0.875rem;
      font-weight: 300;
      color: var(--textLight);
    }

    .dtu-tile-metadata-group {
      display: flex;
    }

    .dtu-tile-metadata {
      display: flex;
      align-items: center;
      height: 20px;
      margin-right: 20px;
      font-size: 0.875rem;
      color: var(--textLight);

      .mat-icon {
        width: 16px;
        height: 16px;
        margin-right: 5px;
        line-height: 16px;
        font-size: 1rem;
      }
    }

    &:empty {
      height: 145px;
      background-image: linear-gradient(to right, transparent 0, var(--loadingAnimation) 50%, transparent 100%),
        linear-gradient(var(--secondaryBackgroundHover) 100%, transparent 0),
        linear-gradient(var(--secondaryBackgroundHover) 100%, transparent 0),
        linear-gradient(var(--secondaryBackgroundHover) 100%, transparent 0),
        linear-gradient(var(--secondaryBackgroundHover) 100%, transparent 0);
      background-size: 1000px 100%, 90px 16px, 220px 22px, 80px 20px, 80px 20px;
      background-position: -1000px 0, 20px 20px, 20px 40px, 20px 105px, 120px 105px;
      background-repeat: no-repeat;
      animation: shimmer 4s infinite;

      @keyframes shimmer {
        0% {
          background-position: -1000px 0, 20px 20px, 20px 40px, 20px 105px, 120px 105px;
        }
        100% {
          background-position: 1000px 0, 20px 20px, 20px 40px, 20px 105px, 120px 105px;
        }
      }
    }
  }
}

a.dtu-tile {
  &:focus,
  &:hover {
    box-shadow: 0 4px 15px 0 var(--boxShadowColorHover);
    border-color: transparent;
    text-decoration: none;
    color: inherit;
    transform: translateY(-4px);
  }

  &.dtu-tile-dashboard,
  &.dtu-tile-flat,
  &.dtu-tile-leaflet {
    &:focus,
    &:hover {
      box-shadow: none;
      transform: none;
    }
  }

  &.dtu-tile-flat {
    .dtu-tile-thumbnail {
      &:empty {
        background-color: var(--secondaryBackground);
        background-image: linear-gradient(90deg, transparent 0, var(--loadingAnimation) 50%, transparent 100%);
        background-position: -100% 0;
        background-size: 50% 100%;
        background-repeat: no-repeat;
        animation: loadingThumbnail 5s infinite;
      }

      @keyframes loadingThumbnail {
        to {
          background-position: 200% 0, 0 0;
        }
      }
    }

    &:focus,
    &:hover {
      background-color: none;
    }

    .dtu-tile-content {
      .dtu-tile-title {
        .dtu-tile-link {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
  }
}

@media only screen and (min-width: $dtu-bp-sm) {
  .dtu-tile {
    width: calc(100% / var(--dtu-tiles-per-row) - 20px);
    margin: 10px;
  }

  a.dtu-tile.dtu-tile-flat {
    .dtu-tile-content {
      .dtu-tile-title {
        .dtu-tile-link {
          -webkit-line-clamp: 2;
        }
      }
    }
  }
}

.dtu-asset-tile {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  text-decoration: none;
  align-items: center;
  border-radius: 5px;
  padding: 20px 14px;
  background: none;
  border: 1px solid transparent;
  color: inherit;
  margin-top: -1px;
  transition: all ease-in-out 0.2s;
  font: inherit;
  cursor: pointer;
  text-align: left;

  &:focus,
  &:hover {
    background-color: var(--backgroundHoverLight);
    border-color: var(--border);
    color: inherit;

    .dtu-asset-tile-actions {
      transition: all ease-in-out 0.2s;
      opacity: 1;
    }
  }

  &::after {
    position: absolute;
    right: 5px;
    bottom: -1px;
    left: 5px;
    height: 1px;
    background-color: var(--border);
    content: '';
  }

  &:empty {
    height: 94px;
    background-color: var(--secondaryBackground);
  }
}

.dtu-asset-tile-thumbnail {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 64px;
  height: 64px;
  border-radius: 5px;
  margin-right: 12px;
  background-color: var(--blue);
  color: white;

  &.registration {
    background-color: var(--purple);
  }

  .mat-icon {
    width: 40px;
    height: 40px;
    font-size: 40px;
    opacity: 0.8;
  }

  img {
    border-radius: 5px;
  }
}

.dtu-asset-tile-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-break: break-word;
  text-overflow: ellipsis;
  margin-bottom: 4px;
}

.dtu-asset-tile-description {
  display: flex;
  align-items: center;
  line-height: 16px;
  font-size: 12px;
  color: var(--textLight);

  .mat-icon {
    width: 16px;
    height: 16px;
    margin-right: 4px;
    margin-bottom: 2px;
    font-size: 16px;
  }
}

.dtu-asset-tile .dtu-asset-tile-actions {
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 0;
  border-radius: 5px 0 5px 0;

  &:focus,
  &:hover {
    transition: all ease-in-out 0.2s;
    opacity: 1;
  }
}

.dtu-select-tiles {
  display: block;

  @media only screen and (min-width: $dtu-bp-md) {
    display: flex;
    flex-wrap: wrap;
  }
}

.dtu-select-tile {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 20px;
  background: var(--secondaryBackground);
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  border: 1px solid var(--secondaryBackground);

  @media only screen and (max-width: $dtu-bp-md) {
    margin-right: 0;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &.selected:not(.disabled),
  &:focus:not(.disabled),
  &:hover:not(.disabled) {
    background: var(--blueLight);
    border: var(--blue) solid 1px;
  }

  &:last-of-type {
    margin-right: 0;
  }

  p {
    margin-bottom: 0;
  }
}

.dtu-new-styles {
  .dtu-tile {
    border-radius: var(--borderRadius);
    box-shadow: var(--boxShadow);

    &.dtu-tile-flat {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: none;
      margin-bottom: 40px;
      box-shadow: none;
      background-color: transparent;
      cursor: pointer;

      .dtu-tile-thumbnail {
        border-radius: var(--borderRadius);

        .dtu-tile-thumbnail-icon {
          border-radius: var(--borderRadius);
        }
      }
    }

    &.dtu-tile-leaflet {
      border-radius: var(--borderRadius);
    }

    &.dtu-tile-dashboard {
      box-shadow: none;

      .dtu-tile-dashboard-icon {
        color: var(--blue);
        background: unset; //remove when new styles are permanent
        -webkit-text-fill-color: unset; //remove when new styles are permanent
      }
    }

    .dtu-tile-thumbnail {
      border-radius: var(--borderRadius) var(--borderRadius) 0 0;

      .dtu-tile-thumbnail-image {
        border-radius: var(--borderRadius) var(--borderRadius) 0 0;
      }

      .dtu-tile-progress {
        border-radius: var(--borderRadius);

        .dtu-tile-progress-current {
          border-radius: var(--borderRadius);
          background: var(--palette-primary-250);

          &.dtu-tile-progress-complete {
            background: var(--green);
          }
        }
      }
    }

    .dtu-tile-flag {
      border-radius: var(--borderRadius);
    }

    .dtu-tile-favorite {
      .mat-icon-button {
        &:focus,
        &:hover {
          color: var(--white);
        }
      }
    }
  }

  a.dtu-tile {
    &:focus,
    &:hover {
      box-shadow: var(--boxShadowHover);
    }
  }

  .dtu-asset-tile {
    border-radius: var(--borderRadius);
  }

  .dtu-asset-tile-thumbnail {
    border-radius: var(--borderRadius);

    img {
      border-radius: var(--borderRadius);
    }
  }

  .dtu-asset-tile .dtu-asset-tile-actions {
    border-radius: var(--borderRadius) 0 var(--borderRadius) 0;
  }

  .dtu-select-tile {
    border-radius: var(--borderRadius);

    &.disabled {
      opacity: var(--opacityDisabled);
    }
  }
}

.dtu-user-tile {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  border-radius: 5px;
  margin-top: -1px;
  padding: 20px 14px;
  border: 1px solid transparent;
  background: none;
  text-decoration: none;
  transition: all ease-in-out 0.2s;
  cursor: pointer;

  &:focus,
  &:hover {
    border-color: var(--border);
    background-color: var(--backgroundHoverLight);
    color: inherit;

    .dtu-user-tile-actions {
      opacity: 1;
    }
  }

  &::after {
    position: absolute;
    right: 5px;
    bottom: -1px;
    left: 5px;
    height: 1px;
    background-color: var(--border);
    content: '';
  }

  &:empty {
    height: 94px;
    background-color: var(--secondaryBackground);
  }
}

.dtu-user-tile-thumbnail {
  position: relative;
  flex-shrink: 0;
  width: 64px;
  height: 64px;
  margin-right: 12px;
  border-radius: 5px;
  line-height: 64px;
  text-align: center;
  text-transform: uppercase;
  font-size: 1rem;
  background-color: var(--secondaryBackground);
  color: var(--text);

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 64px;
    height: 64px;
    border-radius: 5px;
  }
}

.dtu-user-tile-details {
  min-width: 0;
}

.dtu-user-tile-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-break: break-word;
  text-overflow: ellipsis;
  margin-bottom: 4px;
}

.dtu-user-tile-description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.875rem;
  color: var(--textLight);
}

.dtu-user-tile .dtu-user-tile-actions {
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 0;
  border-radius: 5px 0 5px 0;
  transition: all ease-in-out 0.2s;

  &:focus,
  &:hover {
    opacity: 1;
  }
}

.dtu-new-styles :host {
  .dtu-user-tile,
  .dtu-user-tile-thumbnail,
  .dtu-user-tile-thumbnail img {
    border-radius: var(--borderRadius);
  }

  .dtu-user-tile .dtu-user-tile-actions {
    border-radius: var(--borderRadius) 0 var(--borderRadius) 0;
  }
}
